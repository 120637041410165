<div *ngIf="item.customField33 == '2' ">


    <div fxLayout="row" class="table-align" *ngIf="item.customField15 == '1' ">
        <div fxLayout="column" fxFlex="25" class="cell">
        </div>

        <div fxLayout="column" fxFlex="25" class="cell">
            <mat-icon class="maticon1">check_circle</mat-icon>
            <h4><b>Starter</b></h4>
            <button mat-stroked-button color="primary" class="trybtn1">TRY NOW</button>
        </div>

        <div fxLayout="column" fxFlex="25" class="cell">
            <mat-icon class="maticon2">check_circle</mat-icon>
            <h4><b>Professional</b></h4>
            <button mat-stroked-button color="primary" class="trybtn2">TRY NOW</button>
        </div>

        <div fxLayout="column" fxFlex="25" class="cell">
            <mat-icon class="maticon_3">check_circle</mat-icon>
            <h4><b>Advanced</b></h4>
            <button mat-stroked-button color="primary" class="trybtn3">TRY NOW</button>
        </div>
    </div>

    <div fxLayout="row" class="table-align" [style.background-color]="item.customField20"
        [style.color]="item.customField21" *ngIf="item.customField15 == '2' ">
        <div fxLayout="column" [fxFlex]="item.customField19==''?33.33:25" class="cell">
            <h4><b>{{item.customField16}}</b></h4>
        </div>

        <div fxLayout="column" [fxFlex]="item.customField19==''?33.33:25" class="cell">
            <h4><b>{{item.customField17}}</b></h4>
        </div>

        <div fxLayout="column" [fxFlex]="item.customField19==''?33.33:25" class="cell">
            <h4><b>{{item.customField18}}</b></h4>
        </div>

        
        <div fxLayout="column" [fxFlex]="item.customField19==''?33.33:25" class="cell" *ngIf="item.customField19.length>0">
            <h4><b>{{item.customField19}}</b></h4> 
        </div>
        
    </div>
    <div *ngFor="let data of pricedetail">
        <div fxLayout="row" class="subTitle" *ngIf="data.title">
            <p><b>{{data.title}}</b></p>
        </div>

        <div *ngFor="let item of data.list_detail">
            <div fxLayout="row" class="rowtype">
                <div [fxFlex]="item.advanced==''?33.33:25" class="celltype">
                    <p>{{item.sub_title}}</p>
                </div>
                <div [fxFlex]="item.advanced==''?33.33:25" class="celltype">
                    <p style="text-align: center;" *ngIf="item.starter !== 'yes' && item.starter !== 'no'">
                        {{item.starter}}</p>
                    <mat-icon class="maticon3" *ngIf="item.starter === 'yes' ">check_circle</mat-icon>
                    <mat-icon class="maticon4" *ngIf="item.starter === 'no' ">cancel</mat-icon>
                </div>
                <div [fxFlex]="item.advanced==''?33.33:25" class="celltype">
                    <p style="text-align: center;" *ngIf="item.profession !== 'yes' && item.profession !== 'no'">
                        {{item.profession}}</p>
                    <mat-icon class="maticon3" *ngIf="item.profession === 'yes' ">check_circle</mat-icon>
                    <mat-icon class="maticon4" *ngIf="item.profession === 'no' ">cancel</mat-icon>
                </div>
                <div *ngIf="item.advanced.length>0" fxFlex="25%" class="celltype">
                    <p style="text-align: center;" *ngIf="item.advanced !== 'yes' && item.advanced !== 'no'">
                        {{item.advanced}}</p>
                    <mat-icon class="maticon3" *ngIf="item.advanced === 'yes' ">check_circle</mat-icon>
                    <mat-icon class="maticon4" *ngIf="item.advanced === 'no' ">cancel</mat-icon>
                </div>
            </div>
        </div>
    </div>
</div>

<div *ngIf="item.customField33 == '4' ">
    <div fxHide.lt-md>
        <div fxLayout="row" class="table-align" [style.background-color]="item.customField20"
            [style.color]="item.customField21">
            <div fxLayout="column" fxFlex="25" class="cell">
                <h4><b>{{item.customField16}}</b></h4>
            </div>

            <div fxLayout="column" fxFlex="25" class="cell">
                <h4><b>{{item.customField17}}</b></h4>
            </div>

            <div fxLayout="column" fxFlex="25" class="cell" [style.background-color]="item.customField23">
                <h4 style="color: white;"><b>{{item.customField18}}</b></h4>
            </div>

            <div fxLayout="column" fxFlex="25" class="cell">
                <h4><b>{{item.customField19}}</b></h4>
            </div>
        </div>

        <div id="catList">
            <mat-list *ngFor="let items of price_detail; let i = index"
                [ngClass]="{'accordion-list':items.list_detail.length ==0,'':items.list_detail.length >0}" lines="none"
                class="child-list" detail="false" no-padding menuClose style="border-bottom: 1px solid lightgray;">

                <div matRipple tappable [style.background-color]="item.customField20"
                    [ngClass]="{'section-active': items.open, 'section': !items.open}">
                    <div fxLayout="row" fxLayoutAlign="space-between center" style="border-bottom: 1px solid lightgray;border-left: 1px solid lightgray;
                    border-right: 1px solid lightgray;"
                        *ngIf="items.title">
                        <mat-label fxFlex="80" style="padding-left: 10px;font-size: 16px;" color="dark"
                            [style.color]="item.customField23" class="itemLabel padding-10" (click)="toggleSection(i)">
                            {{ items.title }}
                        </mat-label>
                        <mat-icon (click)="toggleSection(i)" matSuffix class="toggleicon"
                            [style.color]="item.customField23"
                            *ngIf="items.list_detail.length >0 && items.open !=true">keyboard_arrow_down</mat-icon>
                        <mat-icon (click)="toggleSection(i)" class="toggleicon" [style.color]="item.customField23"
                            *ngIf="items.open ==true">keyboard_arrow_up</mat-icon>
                    </div>
                </div>

                <div *ngIf="items.list_detail && items.open" menuClose>
                    <div *ngFor="let items of items.list_detail">
                        <div fxLayout="row"
                            style="border-bottom: 1px solid lightgray;border-left: 1px solid lightgray;">
                            <div fxFlex="25%" class="celltype" fxLayoutAlign="start start">
                                <p style="font-size: 16px;padding-top: 20px;padding-bottom: 20px;">{{items.sub_title}}</p>
                            </div>
                            <div fxFlex="25%" class="celltype">
                                <p style="text-align: center;font-size: 16px;padding-top: 20px;padding-bottom: 20px;"
                                    *ngIf="items.starter !== 'yes' && items.starter !== 'no'">
                                    {{items.starter}}</p>
                                <mat-icon class="maticon5" *ngIf="items.starter === 'yes' ">done</mat-icon>
                                <mat-icon class="maticon6" *ngIf="items.starter === 'no' ">close</mat-icon>
                            </div>
                            <div fxFlex="25%" class="celltype" [style.background-color]="item.customField20">
                                <p style="text-align: center;font-size: 16px;padding-top: 20px;padding-bottom: 20px;" [style.color]="item.customField23"
                                    *ngIf="items.profession !== 'yes' && items.profession !== 'no'">
                                    {{items.profession}}</p>
                                <mat-icon class="maticon5" [style.color]="item.customField23"
                                    *ngIf="items.profession === 'yes' ">done</mat-icon>
                                <mat-icon class="maticon6" [style.color]="item.customField23"
                                    *ngIf="items.profession === 'no' ">close</mat-icon>
                            </div>
                            <div fxFlex="25%" class="celltype">
                                <p style="text-align: center;font-size: 16px;padding-top: 20px;padding-bottom: 20px;"
                                    *ngIf="items.advanced !== 'yes' && items.advanced !== 'no'">
                                    {{items.advanced}}</p>
                                <mat-icon class="maticon5" *ngIf="items.advanced === 'yes' ">done</mat-icon>
                                <mat-icon class="maticon6" *ngIf="items.advanced === 'no' ">close</mat-icon>
                            </div>
                        </div>
                    </div>
                </div>

            </mat-list>

        </div>
    </div>

    <div fxHide fxShow.lt-md>

        <div fxLayout="row" class="table-align" [style.background-color]="item.customField20"
        [style.color]="item.customField21">
        <div fxLayout="column" fxFlex="40" class="cell">
            <h4><b>{{item.customField16}}</b></h4>
        </div>

        <div fxLayout="column" fxFlex="20" class="cell">
            <h4><b>{{item.customField17}}</b></h4>
        </div>

        <div fxLayout="column" fxFlex="20" class="cell" [style.background-color]="item.customField23">
            <h4 style="color: white;"><b>{{item.customField18}}</b></h4>
        </div>

        <div fxLayout="column" fxFlex="20" class="cell">
            <h4><b>{{item.customField19}}</b></h4>
        </div>
        </div>

        <div id="catList">
            <mat-list *ngFor="let items of price_detail; let i = index"
                [ngClass]="{'accordion-list':items.list_detail.length ==0,'':items.list_detail.length >0}" lines="none"
                class="child-list" detail="false" no-padding menuClose style="border-bottom: 1px solid lightgray;">

                <div matRipple tappable [style.background-color]="item.customField20"
                    [ngClass]="{'section-active': items.open, 'section': !items.open}">
                    <div fxLayout="row" fxLayoutAlign="space-between center" style="border-bottom: 1px solid lightgray;"
                        *ngIf="items.title">
                        <mat-label fxFlex="80" style="padding-left: 10px;font-size: 11px;" color="dark"
                            [style.color]="item.customField23" class="itemLabel padding-10" (click)="toggleSection(i)">
                            {{ items.title }}
                        </mat-label>
                        <mat-icon (click)="toggleSection(i)" matSuffix class="toggleicon"
                            [style.color]="item.customField23"
                            *ngIf="items.list_detail.length >0 && items.open !=true">keyboard_arrow_down</mat-icon>
                        <mat-icon (click)="toggleSection(i)" class="toggleicon" [style.color]="item.customField23"
                            *ngIf="items.open ==true">keyboard_arrow_up</mat-icon>
                    </div>
                </div>

                <div *ngIf="items.list_detail && items.open" menuClose>
                    <div *ngFor="let items of items.list_detail">
                        <div fxLayout="row" 
                            style="border-bottom: 1px solid lightgray;border-left: 1px solid lightgray;">
                            <div fxFlex="40%" class="celltype" fxLayoutAlign="start start">
                                <p style="font-size: 11px;">{{items.sub_title}}</p>
                            </div>
                            <div fxFlex="20%" class="celltype" fxLayoutAlign="center center">
                                <p style="text-align: center;font-size: 11px;"
                                    *ngIf="items.starter !== 'yes' && items.starter !== 'no'">
                                    {{items.starter}}</p>
                                <mat-icon class="maticon5" *ngIf="items.starter === 'yes' ">done</mat-icon>
                                <mat-icon class="maticon6" *ngIf="items.starter === 'no' ">close</mat-icon>
                            </div>
                            <div fxFlex="20%" class="celltype" [style.background-color]="item.customField20" fxLayoutAlign="center center">
                                <p style="text-align: center;font-size: 11px;" [style.color]="item.customField23"
                                    *ngIf="items.profession !== 'yes' && items.profession !== 'no'">
                                    {{items.profession}}</p>
                                <mat-icon class="maticon5" [style.color]="item.customField23"
                                    *ngIf="items.profession === 'yes' ">done</mat-icon>
                                <mat-icon class="maticon6" [style.color]="item.customField23"
                                    *ngIf="items.profession === 'no' ">close</mat-icon>
                            </div>
                            <div fxFlex="20%" class="celltype" fxLayoutAlign="center center">
                                <p style="text-align: center;font-size: 11px;"
                                    *ngIf="items.advanced !== 'yes' && items.advanced !== 'no'">
                                    {{items.advanced}}</p>
                                <mat-icon class="maticon5" *ngIf="items.advanced === 'yes' ">done</mat-icon>
                                <mat-icon class="maticon6" *ngIf="items.advanced === 'no' ">close</mat-icon>
                            </div>
                        </div>
                    </div>
                </div>

            </mat-list>

        </div>


    </div>
</div>

<div *ngIf="item.customField33 == '13' ">


    <div fxLayout="row" class="table-align" *ngIf="item.customField15 == '1' ">
        <div fxLayout="column" fxFlex="25" class="cell">
        </div>

        <div fxLayout="column" fxFlex="25" class="cell">
            <mat-icon class="maticon1">check_circle</mat-icon>
            <h4><b>Starter</b></h4>
            <button mat-stroked-button color="primary" class="trybtn1">TRY NOW</button>
        </div>

        <div fxLayout="column" fxFlex="25" class="cell">
            <mat-icon class="maticon2">check_circle</mat-icon>
            <h4><b>Professional</b></h4>
            <button mat-stroked-button color="primary" class="trybtn2">TRY NOW</button>
        </div>

        <div fxLayout="column" fxFlex="25" class="cell">
            <mat-icon class="maticon_3">check_circle</mat-icon>
            <h4><b>Advanced</b></h4>
            <button mat-stroked-button color="primary" class="trybtn3">TRY NOW</button>
        </div>
    </div>

    <div fxLayout="row" class="table-align" [style.background-color]="item.customField20"
        [style.color]="item.customField21" *ngIf="item.customField15 == '2' ">
        <div fxLayout="column" [fxFlex]="item.customField19==''?33.33:25" class="cell">
            <h4><b>{{item.customField16}}</b></h4>
        </div>

        <div fxLayout="column" [fxFlex]="item.customField19==''?33.33:25" class="cell">
            <h4><b>{{item.customField17}}</b></h4>
        </div>

        <div fxLayout="column" [fxFlex]="item.customField19==''?33.33:25" class="cell">
            <h4><b>{{item.customField18}}</b></h4>
        </div>

        
        <div fxLayout="column" [fxFlex]="item.customField19==''?33.33:25" class="cell" *ngIf="item.customField19.length>0">
            <h4><b>{{item.customField19}}</b></h4> 
        </div>
        
    </div>
    <div *ngFor="let data of pricedetail">

        <div fxLayout="row" fxLayoutAlign="space-between center" class="subTitle" *ngIf="data.title">
          <p><b>{{ data.title }}</b></p>
          <button mat-icon-button (click)="toggleExpand(data)">
            <mat-icon>{{ data.expanded ? 'expand_less' : 'expand_more' }}</mat-icon>
          </button>
        </div>
      

        <div *ngIf="data.expanded">
          <div *ngFor="let item of data.list_detail">
            <div fxLayout="row" class="rowtype">
              <div [fxFlex]="item.advanced == '' ? 33.33 : 25" class="celltype">
                <p>{{ item.sub_title }}</p>
              </div>
              <div [fxFlex]="item.advanced == '' ? 33.33 : 25" class="celltype">
                <p style="text-align: center;" *ngIf="item.starter !== 'yes' && item.starter !== 'no'">
                  {{ item.starter }}
                </p>
                <mat-icon class="maticon3" *ngIf="item.starter === 'yes'">check_circle</mat-icon>
                <mat-icon class="maticon4" *ngIf="item.starter === 'no'">cancel</mat-icon>
              </div>
              <div [fxFlex]="item.advanced == '' ? 33.33 : 25" class="celltype">
                <p style="text-align: center;" *ngIf="item.profession !== 'yes' && item.profession !== 'no'">
                  {{ item.profession }}
                </p>
                <mat-icon class="maticon3" *ngIf="item.profession === 'yes'">check_circle</mat-icon>
                <mat-icon class="maticon4" *ngIf="item.profession === 'no'">cancel</mat-icon>
              </div>
              <div *ngIf="item.advanced.length > 0" fxFlex="25%" class="celltype">
                <p style="text-align: center;" *ngIf="item.advanced !== 'yes' && item.advanced !== 'no'">
                  {{ item.advanced }}
                </p>
                <mat-icon class="maticon3" *ngIf="item.advanced === 'yes'">check_circle</mat-icon>
                <mat-icon class="maticon4" *ngIf="item.advanced === 'no'">cancel</mat-icon>
              </div>
            </div>
          </div>
        </div>
      </div>
      
</div>