<!-- <div class="pyrmaid-container">

    How does it work?

   <div class="image-container">
<img src="../../../../assets/images/bbimage.jpg" alt="alternateimage
">
   </div>
   <div class="description-container">
    <div class="slider slide-one">
        <div class="slider-heading">
            <h4>1. Join the program</h4>
        </div>
        <div class="slider-description">
<p>Become a Zoho affiliate. Get access to exclusive affiliate portal.</p>
        </div>
    </div>
    <div class="slider slide-two">
        <div class="slider-heading">
<h4>2. Promote the products</h4>
        </div>
        <div class="slider-description">
            <p>Share your unique affiliate links via blogs, social media posts, email newsletters, videos and other digital forums.</p>
        </div>
    </div>
    <div class="slider slide-three">
        <div class="slider-heading">
<h4>3. Earn commissions</h4>
        </div>
        <div class="slider-description">
            <p>Earn a share of the revenue for every qualified sale via your links for the next 12 months.</p>
        </div>
    </div>
   </div>
</div> -->





<div class="pyramid-container " fxHide fxShow.gt-sm>
    <h1 class="main-heading">How does it work?</h1>
  
    <div class="image-container">
      <img src="../../../../assets/images/bbimage.jpg" alt="Illustration" />
    </div>
  
    <div class="description-container">
      <div class="slider slide-one">
        <h4 class="slider-heading">1. Join the program</h4>
        <p class="slider-description">Become a Zoho affiliate. Get access to exclusive affiliate portal.</p>
      </div>
      <div class="slider slide-two">
        <h4 class="slider-heading">2. Promote the products</h4>
        <p class="slider-description">
          Share your unique affiliate links via blogs, social media posts, email newsletters, videos and other digital
          forums.
        </p>
      </div>
      <div class="slider slide-three">
        <h4 class="slider-heading">3. Earn commissions</h4>
        <p class="slider-description">
          Earn a share of the revenue for every qualified sale via your links for the next 12 months.
        </p>
      </div>
    </div>
  </div>


<div class="pyramid-container-one" fxHide fxShow.lt-sm>
    <h1 class="main-heading">How does it work?</h1>
  
    <div class="image-container">
      <img src="../../../../assets/images/bbimage.jpg" alt="Illustration" />
    </div>
  
    <div class="description-container">
      <div class="slider slide-one">
        <h4 class="slider-heading">1. Join the program</h4>
        <p class="slider-description">Become a Zoho affiliate. Get access to exclusive affiliate portal.</p>
      </div>
      <div class="slider slide-two">
        <h4 class="slider-heading">2. Promote the products</h4>
        <p class="slider-description">
          Share your unique affiliate links via blogs, social media posts, email newsletters, videos and other digital
          forums.
        </p>
      </div>
      <div class="slider slide-three">
        <h4 class="slider-heading">3. Earn commissions</h4>
        <p class="slider-description">
          Earn a share of the revenue for every qualified sale via your links for the next 12 months.
        </p>
      </div>
    </div>
  </div>

  