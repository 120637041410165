<div class="container" [fxLayout]="item.customField10 == 'Type 10' ? 'row' : 'row wrap'" fxFlex="100">

  <div *ngFor="let slide of rateList;let i=index"
    [ngStyle]="{ 'margin-right':item.images.length-1==i?'0px':item.gapBetweenImage}" [ngStyle.lt-md]="{
'margin-right': (item.images.length-1==i?'0px':item.displayMobileImage === 1 || 
     (item.displayMobileImage === 3 && i > 1)) ? '0px' : item.mobileGapBetweenImage
}" [fxFlex]="item.layout === 2 ? '100%' : 'auto'" [fxFlex]="item.layout == 2 ? 
(item.gapBetweenImage ?  'calc(50% - ' + item.gapBetweenImage + ')' : '50%') 
: (item.layout == 3 ? (item.gapBetweenImage ? 'calc(33.33% - ' + item.gapBetweenImage + ')' : '33.33%') 
: (item.layout == 4 ? (item.gapBetweenImage ? 'calc(25% - ' + item.gapBetweenImage + ')' : '25%') 
: '100'))" [fxFlex.lt-md]="item.displayMobileImage == 2 ? (item.mobileGapBetweenImage ? 'calc(50% - ' + item.mobileGapBetweenImage + ')' : '50%')
: (item.displayMobileImage == 3 ? (item.mobileGapBetweenImage ? 'calc(33.33% - ' + item.mobileGapBetweenImage + ')' : '33.33%')
: (item.displayMobileImage == 4 ? (item.mobileGapBetweenImage ? 'calc(25% - ' + item.mobileGapBetweenImage + ')' : '25%')
: '100'))" class=" margin-t10 images">

    <div fxLayout="column" fxLayoutAlign="center center"
      style="height: 100%; padding: 10px; border-radius: 10px; box-shadow: 0 4px 8px rgba(0,0,0,0.1);">
      <div class="card-image" style="flex: 1; display: flex; align-items: center;">
        <img [src]="serverPath1 + slide.value" [alt]="slide.value" [style.height]="item.height"
         [ngStyle.lt-md]="{'height': item.heightMob}">
      </div>
      <div class="card-content" style="text-align: center;">
        <h3 style="margin: 10px 0;">{{slide.name}}</h3>
        <p>{{slide.price | currencyConvert}}</p>
      </div>
    </div>


  </div>
</div>