import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class SalescartService {

  constructor(private http: HttpClient) { }

  url = environment.serverAPI4 + '/api/ig/salesrule';

  url1 = environment.serverAPI4 + '/api/ig/salesrule';


  getSalesCart(): Observable<Object> {
    return this.http.get(`${this.url}`);
  }



}
