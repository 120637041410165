import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ContactService } from 'src/app/services/contact.service';

@Component({
  selector: 'calsoft-sub-form',
  templateUrl: './sub-form.component.html',
  styleUrls: ['./sub-form.component.scss']
})
export class SubFormComponent implements OnInit {
  init: boolean = false;
  @Input() formGroupId: any;
  @Input() layout: any = 2;
  @Output() submitSubForm: EventEmitter<any> = new EventEmitter<any>();

  constructor(private contactService:ContactService){

  }
  ngOnInit(): void {
   
   console.log(this.formGroupId);
   
   this.createDynamicForm(this.formGroupId);
  }

  regConfig: any[] = [

  ];

  gaId: any;
  createDynamicForm(formGroupId) {
    this.contactService.getDynmaicForm(formGroupId).subscribe((data) => {

      let value: any = data
      this.regConfig = value;
      this.gaId=this.regConfig[0].gaTrackingId;
      this.formGroupId=formGroupId;
      
      this.init = true;
    });
  }


  submitValue(value: any) {
//sa
    this.submitSubForm.emit(value);

  
   
  }



}
