import { Component, Input, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'calsoft-pyramidimage',
  templateUrl: './pyramidimage.component.html',
  styleUrls: ['./pyramidimage.component.scss']
})
export class PyramidimageComponent implements  OnInit {

  public bannerServerPath = environment.commonImageApi + '/commonSlider/';
  public bannerServerPath1 = environment.commonImageApi + '/home/';

  public serverPath = environment.commonImageApi + '/commonSlider/';

  public serverPath1 = environment.commonImageApi + '/home/';

  @Input() item: any;

  @Input() data: any;

  @Input() img: any;
  
  @Input() index: any;
  
  pyramidRows: any[][] = [];  // Holds the cards per row
  row: any;

  ngOnInit() {
    this.generatePyramidRows();

    this.row.forEach(card => card.isHovered = false);

    this.item.images.forEach(img => img.isHovered = false);
  }

  generatePyramidRows() {
    const totalCards = this.item.images.length;
    let index = 0;

    // First row: 1 card
    if (totalCards > 0) {
      this.pyramidRows.push(this.item.images.slice(0, 1));
      index += 1;
    }

    // Second row: 2 cards
    if (totalCards > 1) {
      this.pyramidRows.push(this.item.images.slice(index, index + 2));
      index += 2;
    }

    // Third row: 3 cards
    if (totalCards > 3) {
      this.pyramidRows.push(this.item.images.slice(index, index + 3));
      index += 3;
    }

    // Fourth row and above: 3 cards per row
    while (index < totalCards) {
      this.pyramidRows.push(this.item.images.slice(index, index + 3));
      index += 3;
    }
  }
}
