import { Component, Input, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'calsoft-editor-text-child',
  templateUrl: './editor-text-child.component.html',
  styleUrls: ['./editor-text-child.component.scss']
})
export class EditorTextChildComponent implements OnInit {
  trustedHtmlContent: any;
  @Input() text: any;

  @Input() align: any;

  @Input() data: any;

  @Input() fontSize: any;

  @Input() MobfontSize: any;

  @Input() fontFamily: any;

  @Input() id: any;

  constructor(
   private sanitizer: DomSanitizer,
   

  ) {
    
  }

  ngOnInit() {    
    
    this.trustedHtmlContent = this.sanitizer.bypassSecurityTrustHtml(this.text);
  }

}
