<div style="width: 100%;" fxLayout="row" fxLayout.sm="column" fxLayout.xs="column" fxLayoutGap="10px">
  <mat-form-field fxFlex="100" fxFlex.sm="100" fxFlex.xs="100" appearance="outline" [formGroup]="group">
    <mat-label>{{field.label}}
      <span *ngIf="isRequired(field.name)" class="required-symbol">*</span>
    </mat-label>
    <input matInput [formControlName]="field.name" [type]="field.inputType">

    <!-- Updated mat-error with null check -->
    <ng-container *ngFor="let validation of field['dynamicFormLabelValidator'];" ngProjectAs="mat-error">
      <mat-error *ngIf="group.get(field.name) && group.get(field.name).hasError(validation.name)">
        {{validation.message}}
      </mat-error>
    </ng-container>

    <mat-hint [align]="start" *ngFor="let validation of field['dynamicFormLabelValidator'];">
      {{validation.hintMessage}}
    </mat-hint>

  </mat-form-field>

  <button mat-raised-button *ngIf="customField13 == 1"
  style="margin: 0px 10px; border-radius: 12px; height: 57px; padding: 0 30px; font-size: 16px; font-weight: bold; color: white; background-color: #3f51b5; transition: background-color 0.3s ease, box-shadow 0.3s ease;"
  color="primary" 
  (click)="setValue(group.get(field.name).value)"
  (mouseover)="hover = true"
  (mouseleave)="hover = false"
  [style.boxShadow]="hover ? '0 8px 16px rgba(0, 0, 0, 0.2)' : 'none'"
  [style.backgroundColor]="hover ? '#2c3e9a' : '#3f51b5'"
>
  Submit
</button>

</div>
