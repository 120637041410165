<div class="container">
    <div class="p-b20" *ngFor="let item of couponList">
        <div class="card">
            <h3>Terms and Conditions: <span>{{item.name}}</span></h3>
            <div fxLayout="column" fxLayoutGap="5px">
                <div fxLayout="row">
                    <div *ngIf="item.salesRuleCoupon" fxFlex="15" class="couponCode">
                        <div (click)="copyInputMessage(item.salesRuleCoupon[0].code)"  fxLayout="row" mat-elevation-z6  fxLayoutAlign="center center">
                            {{item.salesRuleCoupon[0].code}}
                        </div>
                    </div>
                </div>
                <p>Validity : <b>{{item.fromDate | date}}</b> - <b>{{item.toDate | date}}</b></p>
            </div>
            <div fxLayout="row">
                <b> Description:</b> <span>&nbsp; {{item.description}} </span>
            </div>
        </div>
    </div>
</div>