import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, UntypedFormGroup, Validators } from '@angular/forms';
import { SubscriptionPriceService } from 'src/app/services/subscription-price.service';
import { FieldConfig } from '../field.interface';

@Component({
  selector: 'calsoft-select',
  templateUrl: './select.component.html',
  styleUrls: ['./select.component.scss']
})
export class SelectComponent implements OnInit {

  @Input() field: FieldConfig;
  @Input() group: UntypedFormGroup;
  @Input() index: any;
  @Input() indexInital: any;
  @Output() valueChange = new EventEmitter<any>();

  @Output() selectedValueChange = new EventEmitter<string>();

  optionsList: string[] = [];
  localFormControl: FormControl;
  reInit: boolean = false;

  selectedCustomFieldValue: string = '';
  customField5Values: string[] = [];
  selectedValue: string = '';
  selectedOptions: any;

  constructor(private subscriptionService: SubscriptionPriceService,
    private _formBuilder: FormBuilder,
  ) { }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.field && changes.field.currentValue) {
      this.initializeOptions();
    }
  }

  secondFormGroup: FormGroup;

  ngOnInit() {
    this.localFormControl = new FormControl('');
    this.initializeOptions();

    this.secondFormGroup = this._formBuilder.group({
      amount: ['', Validators.required],

    });

    this.secondFormGroup.get('amount')?.valueChanges.subscribe((amount) => {
      this.selectedCustomFieldValue = amount; // Update selectedCustomFieldValue
      this.subscriptionService.updateAmount(this.selectedCustomFieldValue); // Update subscription service
      console.log(this.selectedCustomFieldValue); // Log for debugging
    });

    const control = this.group.get(this.field.name);

  }

  private initializeOptions(): void {
    this.localFormControl = new FormControl('');
    this.localFormControl.reset();
    let value: any = this.field.options;
    this.optionsList = value ? value.split(',') : [];

    this.customField5Values = this.field.customfield5 ? this.field.customfield5.split(',') : [];


    if (this.optionsList.length > 0) {
      const defaultValue = this.optionsList[0];
      this.localFormControl.setValue(defaultValue);
      this.setValue(defaultValue);
    }

    if (this.group.value[this.field.name] != null && this.group.value[this.field.name] != '') {
      this.localFormControl.setValue(this.group.value[this.field.name] || '');
    }

    this.reInit = true;
  }

  isRequired(fieldName: string): boolean {
    const control = this.group.get(fieldName);
    return control && control.hasError('required');
  }




  setValue(value: string): void {

    this.selectedValue = value;

    this.localFormControl.setValue(value);

    const control = this.group.get(this.field.name);
    if (control) {
      control.setValue(value);
      this.valueChange.emit(this.field.name);
    }

    if (this.field.customfield4 === '2') {
      const selectedIndex = this.optionsList.indexOf(value);
      if (selectedIndex > -1 && this.customField5Values[selectedIndex]) {
        this.selectedCustomFieldValue = this.customField5Values[selectedIndex];
      } else {
        this.selectedCustomFieldValue = '';
      }
    } else if (this.field.customfield4 === '4') {
      this.selectedOptions = value; 

      this.selectedCustomFieldValue = this.secondFormGroup.value.amount;

    }

    this.selectedValueChange.emit(this.selectedCustomFieldValue);

    this.subscriptionService.updateAmount(this.selectedCustomFieldValue)

  }
}
