<div fxFlex="100" [ngStyle]="{'text-align': productNameCenter==1 ? 'center' : 'start'}" class="ProductName wrap"
    *ngIf="type=='product'">
    <b class="modernWay"> {{translateValue }}</b>
</div>
<div fxFlex="100" [ngStyle]="{'text-align': productNameCenter==1 ? 'center' : 'start'}" *ngIf="type=='products'" fxHide
    fxShow.gt-md class="f16 text123444"> <b class="modernWay"> {{translateValue }}</b>
</div>
<div fxFlex="100" [ngStyle]="{'text-align': productNameCenter==1 ? 'center' : 'start'}" *ngIf="type=='products'"
    class="f16 text123444" fxHide fxShow.md fxShow.lt-md> <b class="modernWay"> {{translateValue }}</b>
</div>

<p class="ProductNames" [ngStyle]="{'text-align': productNameCenter==1 ? 'center' : 'start'}"
    *ngIf="type=='productgrid'"><b> {{translateValue }}</b></p>

<div fxFlex="100" [ngStyle]="{'text-align': productNameCenter==1 ? 'center' : 'start'}" *ngIf="type=='productsNew'"
    fxHide fxShow.gt-md class="f16 "> <b [style.-webkit-line-clamp]="categoryPageProductNameDisplayLine"
        [style.line-clamp]="categoryPageProductNameDisplayLine" class="modernWay1"> {{translateValue }}</b>
</div>

<div fxFlex="100" [ngStyle]="{'text-align': data.productNameCenter==1 ? 'center' : 'start'}" *ngIf="type=='productsNew'"
    class="f16 " fxHide fxShow.md fxShow.lt-md> <b
        [ngStyle.lt-md]="{ '-webkit-line-clamp': categoryPageProductNameDisplayLineMobHeight, 'line-clamp': categoryPageProductNameDisplayLineMobHeight }"
        class="modernWay1"> {{translateValue }}</b>
</div>

<div fxFlex="100"  *ngIf="type=='productsdetails'"
    class="f16 "> <b> {{translateValue }}</b>
</div>

<div fxFlex="100" [ngStyle]="{'text-align': productNameCenter==1 ? 'center' : 'start'}" class="ProductName"
    *ngIf="type=='productcourse'">
    <p style="font-size: 15px;"><b class="modernWay"> {{translateValue }}</b></p>
</div>