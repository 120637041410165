<div [ngClass]="categoryId4 === 0 ? 'border' : 'noclass'">

  <div *ngIf="categoryId5==0">
    <calsoft-common-header-carousel [other]="other" [item]="item" [header]="header"
      [id]="id"></calsoft-common-header-carousel>
  </div>
  <div *ngIf="categoryId5==1">

  </div>


  <div *ngIf="categoryId5==2||categoryId5==3">
    <div [style.background-color]="item.customField25" class="commonHeader" fxLayout="row"
      fxLayoutAlign="space-between center">
      <span style="   
      font-weight: 500;"  [ngStyle.gt-md]="{'font-size': item.customField39 }"
                        [ngStyle.lt-md]="{
                            
                            'font-size': item.customField40
                        }">{{header | uppercase}}</span>
    </div>
  </div>

  <div>
    <div fxLayout="row wrap" >
      <ng-container *ngFor="let product of categoryProducts; let i=index">
        <div class="br5"  [fxFlex.xs]="calculateMobileFlex(mobileSize2)" fxLayoutGap="10px"   [fxFlex]="calculateFlex(size)">
          <div fxLayoutAlign="center center" ngClass.gt-md="carousel-gap" style="border: 2px solid #eee;">
            <calsoft-common-product-details-carousel [data]="data" [width]="width" [imageType]="imageType"
              [height]="height" [heightMobile]="heightMobile" [widthMobile]="widthMobile"
              [showConfigurableDropdown]="showConfigurableDropdown" [showCartIcon]="showCartIcon"
              [showCartIncremented]="showCartIncremented" [product]="product"></calsoft-common-product-details-carousel>
  
          </div>
  
        </div>
      </ng-container>
    </div>
  
  </div>

  <div  *ngIf="categoryId5==3" fxLayout="row" fxLayoutAlign="center center" style="padding-top: 10px; padding-bottom: 10px;">
    <button  class=" btn"
    (click)="goToCategory()" >{{item.customField26}}</button>
  </div>

</div>