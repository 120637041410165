import { Component, OnInit } from '@angular/core';
import { SalescartService } from 'src/app/services/salescart.service'
import { Clipboard } from '@angular/cdk/clipboard'
import { MatSnackBar } from '@angular/material/snack-bar';
@Component({
  selector: 'app-coupon',
  templateUrl: './coupon.component.html',
  styleUrls: ['./coupon.component.scss']
})
export class CouponComponent implements OnInit {
  couponList: Object;
  textMessageFunc: any;

  constructor(
    private salesCartService: SalescartService,
    private clipboard: Clipboard,
    private snackBar: MatSnackBar
  ) { }

  ngOnInit(): void {
    this.getCoupon();
  }


  getCoupon() {
    this.salesCartService.getSalesCart().subscribe(
      data => {
        this.couponList = data['content'];

      }
    )
  }


  copyInputMessage(i) {
    this.clipboard.copy(`${i}`);
    this.snackBar.open(`Coupon Code Copied `, '×', { panelClass: 'success', verticalPosition: 'bottom', duration: 3000 });

  }




}
