import { Component } from '@angular/core';

@Component({
  selector: 'calsoft-pyramidtext',
  templateUrl: './pyramidtext.component.html',
  styleUrls: ['./pyramidtext.component.scss']
})
export class PyramidtextComponent {

}
