<div  >
    <div *ngIf="display">

        
        <div *ngIf="hidePage" class="container">
        
            <div class="header-wrapper "  style="width: 100%" fxLayout="row" fxLayoutAlign="space-between center">
                <div class="logo">
                    <calsoft-logo [sitename]="data.siteName" [type]="'dark'"></calsoft-logo>

                </div>
                <div fxLayout="row" fxFlex="80">
                    <mat-toolbar-row fxHide fxShow.gt-sm class="container" fxLayout="row" fxLayout.xs="row"
                        fxLayoutAlign="space-between center">
                        <calsoft-Menu  [newcolor]="true" [color]="true" [data]="data" [type]="'one'" style="color: #000000;" class="menu"
                            [categoryArray]="item"></calsoft-Menu>
                    </mat-toolbar-row>
                    <button mat-button fxLayoutAlign="end center">
                        <mat-icon (click)="callPhone()" style="color: black;">phone_in_talk</mat-icon>
                    </button>
            

                    <!-- <calsoft-cart-icon [data]="data"  fxFlex></calsoft-cart-icon> -->
                </div>

            </div>

           
        </div>


        <div *ngIf="!hidePage" class="header container">
            <div class="header-wrapper" fxLayout="row" fxLayoutAlign="space-between center">
                <div >
                    <calsoft-logo [sitename]="data.siteName" [type]="'white'"></calsoft-logo>

                </div>
                <div fxLayout="row" fxFlex="80">
                    <mat-toolbar-row fxHide fxShow.gt-sm class="container" fxLayout="row" fxLayout.xs="row"
                        fxLayoutAlign="space-between center">
                        <calsoft-Menu [color]="true" [data]="data" [type]="'one'" style="color: #000000;" class="menu"
                            [categoryArray]="item"></calsoft-Menu>
                    </mat-toolbar-row>

                    <button mat-button fxLayoutAlign="end center">
                        <mat-icon (click)="callPhone()" style="color: white;">phone_in_talk</mat-icon>
                    </button>

                    <!-- <calsoft-cart-icon [data]="data"  fxFlex></calsoft-cart-icon> -->
                </div>

            </div>
        </div>

        <mat-toolbar style="z-index: 1000;width: 100%;background-color: white;" fxHide fxShow.gt-md fxShow.md  class="calsoft-fixed-header bg toolbar-container" 
        fxLayout="row"
          >
          <mat-toolbar-row style="height: 100%;"  fxLayoutAlign="space-between center">
            <div class="header-wrapper container"  style="width: 100%" fxLayout="row" fxLayoutAlign="space-between center">
                <div class="logo">
                    <calsoft-logo [sitename]="data.siteName" [type]="'dark'"></calsoft-logo>

                </div>
                <div fxLayout="row" fxFlex="80">
                    <mat-toolbar-row fxHide fxShow.gt-sm class="container" fxLayout="row" fxLayout.xs="row"
                        fxLayoutAlign="space-between center">
                        <calsoft-Menu  [newcolor]="true" [color]="true" [data]="data" [type]="'one'" style="color: #000000;" class="menu"
                            [categoryArray]="item"></calsoft-Menu>
                    </mat-toolbar-row>
                    <button mat-button fxLayoutAlign="end center">
                        <mat-icon (click)="callPhone()" style="color: black;">phone_in_talk</mat-icon>
                    </button>
            
                </div>

            </div>
            </mat-toolbar-row>
          
        </mat-toolbar>

     

    </div>
</div>

<div *ngIf="!display">

    <div *ngIf="mobileHeaderType==1">
        <calsoft-mobile-header [data]="data" [color]="'white'" [url]="url" [userLoggedIn]="userLoggedIn">

        </calsoft-mobile-header>
    </div>

    <div *ngIf="mobileHeaderType==0">
        <calsoft-mobile-header-new [data]="data" [color]="'white'" [url]="url" [userLoggedIn]="userLoggedIn">

        </calsoft-mobile-header-new>
    </div>

    <div *ngIf="mobileHeaderType==2">
        <calsoft-mobile-header3 [data]="data" [color]="'white'" [url]="url" [userLoggedIn]="userLoggedIn">

        </calsoft-mobile-header3>
    </div>

    <div *ngIf="mobileHeaderType==3">
        <calsoft-mobile-header4 [data]="data" [color]="'white'" [url]="url" [userLoggedIn]="userLoggedIn">
        </calsoft-mobile-header4>
    </div>

    <div *ngIf="mobileHeaderType==4">
        <calsoft-mobile-header5 [data]="data" [color]="'white'" [url]="url" [userLoggedIn]="userLoggedIn">
        </calsoft-mobile-header5>
    </div>

    <div *ngIf="mobileHeaderType==5">
        <calsoft-mobile-header6 [data]="data" [color]="'white'" [url]="url" [userLoggedIn]="userLoggedIn">

        </calsoft-mobile-header6>
    </div>
    <div *ngIf="mobileHeaderType==6">
        <calsoft-mobile-header7 [item]="item" [data]="data" [color]="'white'" [url]="url" [userLoggedIn]="userLoggedIn">

        </calsoft-mobile-header7>
    </div>
    <div *ngIf="mobileHeaderType==7">

        <calsoft-mobile-header8 [item]="item" [data]="data" [color]="'white'" [url]="url" [userLoggedIn]="userLoggedIn">

        </calsoft-mobile-header8>
    </div>

    <div *ngIf="mobileHeaderType==8">    
        <calsoft-mobile-header9 [item]="item" [data]="data" [color]="'white'" [url]="url" [userLoggedIn]="userLoggedIn">
        </calsoft-mobile-header9>
    </div>

</div>