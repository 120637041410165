import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Component, DoCheck, HostListener, Input, OnChanges, OnInit, SimpleChanges, TemplateRef, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { CarouselComponent } from 'ngx-owl-carousel-o';
import { CategoryService } from 'src/app/services/category.service';
import { CommonService } from 'src/app/services/common.service';


import { environment } from 'src/environments/environment';
import { YoutubeVideoComponent } from '../youtube-video/youtube-video.component';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';


@Component({
  selector: 'calsoft-brands',
  templateUrl: './brands.component.html',
  styleUrls: ['./brands.component.scss']
})
export class BrandsComponent implements OnInit {

  @Input() brands: any[] = [];
  @Input() formGroupId: any;
  @Input() item: any;
  width: any;
  height: any;
  gapBetweenImage: any;
  customField12: any;
  customField11: any;
  isMobile: boolean = false;

  public bannerServerPath = environment.commonImageApi + '/commonSlider/';
  public bannerServerPath1 = environment.commonImageApi + '/home/';

  public LogoSliderConfig: any;
  public LogoSliderConfig1: any;
  public LogoSliderConfig4: any;

  imagesPerLoad = 2;
  loadedImages = 2;
  @Input() data: any;
  public youtubeUrl = environment.youtubeUrl;
  @ViewChild('owlElement', { static: false }) owlElement: CarouselComponent;
  regConfig: any[] = [

  ];
  displayWebCount: number;
  displayMobileCount: number;
  constructor(private commonService: CommonService,
    private sanitizer: DomSanitizer,
    private dialog: MatDialog,
    private categoryService: CategoryService,
    private breakpointObserver: BreakpointObserver) {
      this.breakpointObserver.observe([Breakpoints.Handset])
      .subscribe(result => {
        this.isMobile = result.matches;
      });
     }
 

     sanitizeHtml(html: string): SafeHtml {
      return this.sanitizer.bypassSecurityTrustHtml(html);
    }


  @HostListener('window:scroll', ['$event'])
  nScroll(event) {
    
    if ((window.innerHeight + window.scrollY) >= document.body.offsetHeight) {
      this.loadMoreImages();
    }
  }



  getStyleClass(index: number): string {
    const row = Math.floor(index / 3); // Determine the row number
    const col = index % 3; // Determine the column within the row

    // If the column is odd (1 or 3 in zero-based index), apply alternate style
    return (col % 2 === 1) ? 'alt-style' : '';
  }

  calcFlex(): string {
    return 'calc(33.33% - 20px)';
  }
  carouselOptions = {
    loop: true, // Ensures continuous sliding
    margin: 10, // Adds space between the cards
    nav: false, // Disables navigation buttons (next and previous)
    dots: false, // Disables pagination dots
    autoplay: true, // Enables automatic sliding
    autoplayTimeout: 3000, // Sets the interval between automatic slides
    autoplayHoverPause: true, // Pauses autoplay on hover
    items: 3, // Specifies the number of items visible at a time
    responsive: {
      0: {
        items: 1 // Shows 1 card on small screens
      },
      600: {
        items: 3 // Shows 2 cards on medium screens
      },
      1000: {
        items: 4 // Shows 3 cards on larger screens
      }
    },
    slideBy: 1, // Slides one item at a time
    smartSpeed: 800, // Controls the speed of the slide transition
    rewind: true, // Rewinds to the first slide when the last slide is reached
    animateOut: 'fadeOut', // Animation effect when a slide goes out
    animateIn: 'fadeIn' // Animation effect when a slide comes in
  };




  loadMoreImages() {
    this.loadedImages += this.imagesPerLoad;
  }
   
  @HostListener('window:scroll', ['$event'])
  onScroll(event) {
    
    if ((window.innerHeight + window.scrollY) >= document.body.offsetHeight) {
      this.loadMoreImages();
    }
  }
  @HostListener('window:scroll', ['$event'])
  onWindowScroll(event) {
 
    const elements = document.querySelectorAll('.feature-row');
    elements.forEach((el: any) => {
      const rect = el.getBoundingClientRect();
      if (rect.top >= 0 && rect.bottom <= (window.innerHeight || document.documentElement.clientHeight)) {
        el.classList.add('slide-in');
      
      } else {
        el.classList.remove('slide-in');
       
      }
    });
  }
  
  LogoSlider = {
    nav: false,
    dots: false,
    items: 3,
    margin: 24,
    loop: true,
    autoHeight: true,
    autoplay: true,
    autoplayTimeout: 3000,
    responsive: {
      1200: { items: 5, margin: 24 },
      992: { items: 4, margin: 20 },
      768: { items: 3, margin: 16 },
      576: { items: 2, margin: 12 },
      0: { items: 1, margin: 8 },
    },
  };
  

  LogoSlider1 = {
    ...sliderOpt,
    nav: false,
    dots: true,
    items: 3,
    margin: 20,
    loop: true,
    autoHeight: true,
    autoplay: true,
    autoplayTimeout: 3000,
    responsive: {
      767: {
        items: 5
      },
      576: {
        items: 4
      },
      600: {
        items: 3
      },
      0: {
        items: 2
      }
    }
  }
  value: any;
  value2: any;

  ngOnInit(): void {    

    this.value2 = 20 * 2;

    this.value = 100 - this.value2;

    let value=parseInt(this.item.customField14)+1

   this.displayWebCount= 100/value;

   
   let value1=parseInt(this.item.customField33)+1
   this.displayMobileCount= 100/value1;

    if (this.item.gapBetweenImage.endsWith('px')) {
      this.item.gapBetweenImage = this.item.gapBetweenImage.slice(0, -2);
    }

    this.LogoSlider.autoplayTimeout = this.item.customField21;

    this.LogoSlider1.autoplayTimeout = this.item.customField21;

    this.LogoSlider2.autoplayTimeout = this.item.customField21;

    this.LogoSlider3.autoplayTimeout = this.item.customField21;

    this.LogoSlider4.autoplayTimeout = this.item.customField21;

    this.LogoSlider5.autoplayTimeout = this.item.customField21;

    this.LogoSlider6.autoplayTimeout = this.item.customField21;

    this.LogoSlider.margin = this.item.gapBetweenImage;

    this.carouselOptions.margin = this.item.gapBetweenImage;

    this.LogoSliderConfig = this.LogoSlider;

    this.LogoSlider1.margin = this.item.gapBetweenImage;
    this.LogoSliderConfig1 = this.LogoSlider1;

    this.LogoSlider4.margin = this.item.gapBetweenImage;
    this.LogoSliderConfig4 = this.LogoSlider4;

    this.LogoSlider1.responsive[767].items = this.item.customField14;
    this.LogoSlider1.responsive[576].items = this.item.customField14;

    // this.LogoSlider.responsive[767].items = this.item.customField14;
    // this.LogoSlider.responsive[576].items = this.item.customField14;

    this.LogoSlider1.responsive[600].items = this.item.customField33;
    this.LogoSlider1.responsive[0].items = this.item.customField33;


    this.LogoSlider4.responsive[767].items = this.item.customField14;
    this.LogoSlider4.responsive[576].items = this.item.customField14;

    this.LogoSlider6.responsive[767].items = this.item.customField14;
    this.LogoSlider6.responsive[576].items = this.item.customField14;

    this.LogoSlider.responsive[0].items = this.item.customField33


    this.LogoSlider7.responsive[767].items = this.item.customField14;
    this.LogoSlider7.responsive[576].items = this.item.customField33;

    this.LogoSlider7.responsive[480].items = this.item.customField33;
    this.LogoSlider7.responsive[0].items = this.item.customField33;
  }


  customOptions = {
    loop: true,
    mouseDrag: true,
    touchDrag: true,
    pullDrag: false,
    dots: false,
    navSpeed: 600,
    navText: ['&#8249', '&#8250;'],
    responsive: {
      0: {
        items: 1
      },
      400: {
        items: 1
      },
      760: {
        items: 1
      },
      1000: {
        items: 1
      }
    },
    nav: true
  }

  goToCategory(menu) {
    this.commonService.selectedCategory(menu.categoryName, menu.categoryId, menu);

  }


  imageNavigation(id) {

    if (id > 0) {
      this.commonService.goToCategory(1, parseInt(id));
    }

  }

  catId: any;

  goToCategories(id: any) {
    if (id > 0) {
      this.commonService.goToCategory(1, parseInt(id));
    }

  }

  link(url) {

    this.commonService.goToLink(url)
   
  }

  @ViewChild('owlCar') owlCarousel: CarouselComponent;

  LogoSlider5 = {
    nav: false,
    dots: false,
    items: 1,
    margin: 20,
    loop: true,
    autoHeight: true,
    autoplay: true,
    autoplayTimeout: 3000,
    responsive: {
      767: {
        items: 1
      },
      576: {
        items: 1
      },
      480: {
        items: 1
      },
      0: {
        items: 1
      }
    }
  }

  LogoSlider2 = {
    nav: false,
    dots: true,
    items: 1,
    margin: 20,
    loop: true,
    autoHeight: true,
    autoplay: true,
    autoplayTimeout: 3000,
    responsive: {
      767: {
        items: 1
      },
      576: {
        items: 1
      },
      480: {
        items: 1
      },
      0: {
        items: 1
      }
    }
  }

  LogoSlider3 = {
    nav: false,
    dots: true,
    items: 1,
    margin: 20,
    loop: true,
    autoHeight: true,
    autoplay: true,
    autoplayTimeout: 3000,
    responsive: {
      767: {
        items: 5
      },
      576: {
        items: 4
      },
      480: {
        items: 3
      },
      0: {
        items: 2
      }
    }
  }


  LogoSlider4 = {
    ...sliderOpt,
    nav: true,
    dots: false,
    items: 3,
    margin: 20,
    loop: true,
    autoHeight: true,
    autoplay: true,
    navText: ['&#8249', '&#8250;'],
    autoplayTimeout: 3000,
    responsive: {
      767: {
        items: 5
      },
      576: {
        items: 4
      },
      480: {
        items: 3
      },
      0: {
        items: 1,
        stagePadding: 30
      }
    }
  }

  LogoSlider6 = {
    ...sliderOpt,
    nav: false,
    dots: false,
    items: 3,
    margin: 20,
    loop: true,
    autoHeight: true,
    autoplay: true,
    navText: ['&#8249', '&#8250;'],
    autoplayTimeout: 3000,
    responsive: {
      767: {
        items: 1
      },
      576: {
        items: 1
      },
      480: {
        items: 1
      },
      0: {
        items: 1
      }
    }
  }

  LogoSlider7 = {
    ...sliderOpt,
    nav: false,
    dots: true,
    items: 4,
    margin: 20,
    loop: true,
    autoHeight: true,
    autoplay: false,
    navText: ['&#8249', '&#8250;'],
    autoplayTimeout: 3000,
    responsive: {
      767: {
        items: 4
      },
      576: {
        items: 1
      },
      480: {
        items: 1
      },
      0: {
        items: 1
      }
    }
  }


  @ViewChild('vedio') vedio: TemplateRef<any>;

  showModal(data: any) {
    if (window.innerWidth > 768) {

      let dialogRef = this.dialog.open(YoutubeVideoComponent, {
        width: '790px',
        height: '500px',
        data: { data: data, }
      });

      dialogRef.disableClose = true;
      dialogRef.afterClosed().subscribe(result => {
        this.ngOnInit();


      });

    }
    else {
      let dialogRef = this.dialog.open(YoutubeVideoComponent, {
        minWidth: '100vw', height: '100vh',
        data: { data: data, }
      });

      dialogRef.disableClose = true;
      dialogRef.afterClosed().subscribe(result => {
        this.ngOnInit();


      });
    }
  }

  closeModal() {
    this.dialog.closeAll();
  }


}
export const sliderOpt = {
  items: 1,
  loop: true,
  margin: 20,
  responsiveClass: true,
  nav: true,
  navText: ['<i class="icon-angle-left">', '<i class="icon-angle-right">'],
  dots: true,
  smartSpeed: 400,
  autoplay: false,
  autoplayTimeout: 15000
}