import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { CouponComponent } from 'src/app/pages/coupon/coupon.component';
import { SalescartService } from 'src/app/services/salescart.service';
import { Clipboard } from '@angular/cdk/clipboard'

@Component({
  selector: 'app-apply-coupon',
  templateUrl: './apply-coupon.component.html',
  styleUrls: ['./apply-coupon.component.scss']
})
export class ApplyCouponComponent {
  couponList: Object;
  textMessageFunc: any;

  constructor(
     @Inject(MAT_DIALOG_DATA) public data: any,  
    private dialogRef: MatDialogRef<ApplyCouponComponent>  ,

    private salesCartService: SalescartService,
    private clipboard: Clipboard,
    private snackBar: MatSnackBar
  ) { }

  selectCoupon(code: string) {
    this.dialogRef.close(code);  // Close the dialog and pass the selected coupon code to the parent
  }

  closeDialog() {
    this.dialogRef.close();  // Close dialog without passing any data
  }
  ngOnInit(): void {
    this.getCoupon();
  }


  getCoupon() {
    this.salesCartService.getSalesCart().subscribe(
      data => {
        this.couponList = data['content'];

      }
    )
  }


  copyInputMessage(i) {
    this.clipboard.copy(`${i}`);
    this.snackBar.open(`Coupon Code Copied `, '×', { panelClass: 'success', verticalPosition: 'bottom', duration: 3000 });

  }




}
