import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class DeployService {

  url = environment.serverAPI7 + '/api';

  constructor(private http: HttpClient) { }

  AddNewSite(site:any ): Observable<Response[]> {
    return this.http.post<Response[]>(`${this.url}/format`, site);
  }
  
}
