import { Component, OnInit, Input } from '@angular/core';


import { MatDialog } from '@angular/material/dialog';

import { Constant } from 'src/app/constants/constant';
import { CurrencyConversionService } from 'src/app/services/currency-conversion.service';
import { CommonService } from 'src/app/services/common.service';
import { ProductService } from 'src/app/services/product.service';
import { UserService } from 'src/app/services/user.service';
import { CartService } from 'src/app/services/cart.service';
import { Router } from '@angular/router';
import { Product } from 'src/app/models/app.models';
import { SwiperConfigInterface } from 'src/app/theme/swiper/swiper.module';

@Component({
  selector: 'calsoft-product-carousel',
  templateUrl: './productcarousel.component.html',
  styleUrls: ['./productcarousel.component.scss']
})
export class CalsoftProductcarouselComponent implements OnInit {
  @Input() categoryId: any;
  @Input() header: any;
  @Input() id: any;
  @Input() productImageHeight:any;
  @Input()  showCartIcon : any;
  @Input()  showCartIncremented : any;
  @Input() data: any;
  @Input() showConfigurableDropdown: any;
  @Input() urlNavigation: any=true;
  @Input() heightMobile: any;
  @Input() widthMobile: any;
  @Input() sortOrder: any;
  @Input() other: any;
  @Input() type: any="home";
  @Input() height: any;
  @Input() width: any;
  @Input() imageType: any; 
  @Input() item: any;
  @Input() slidesPerView: any;
  categoryProducts: any;
  count: any;
  isEmpty: any;
  pageSize: any;
  public page: any = 0;
  size: number = 10;
  sortParam: any;
  @Input('products') products: Array<Product> = [];
  public config: SwiperConfigInterface = {};
  countryCode: string;
  url: string;

  categoryId5: any=0;
  categoryId4:any=0;
  customField27: any;
  customField28: any;
  customField29: any;
  @Input() border: any = 0;

  @Input() gap: any = 0;

  @Input() displayTitle: any = 0;
  mobileShow: number=1;

  // public settings: Settings;
  constructor(
    public dialog: MatDialog,
    private commonService: CommonService,
    private currencyConversionService: CurrencyConversionService,
    private productService: ProductService,
    private userService: UserService,
    private cartServices: CartService,
    private router:Router
  ) {

  }


  ngOnInit(): void {
    
    if (this.item?.customField30 !== undefined) {
      this.mobileShow = this.item.customField30;
    }
    
      if (this.slidesPerView != undefined) {
        this.slidesPerView = this.item.customField29;
      }
  
    

    this.url=this.router.url;

    if(this.type!='product'){
      this.productsByCategory();
    }else{
      this.productsByList();
    }

    if(this.item!=undefined){
    if(this.categoryId4!=undefined){
      this.categoryId4=this.item.categoryId4;
    }

    if(this.categoryId5!=undefined){
      this.categoryId5=this.item.categoryId5;
    }

    if(this.item.customField27!=undefined){
      document.documentElement.style.setProperty('--custom-width', this.item.customField27);
      this.customField27=this.item.customField27;      
    }

    
    if(this.item.customField28!=undefined){
      document.documentElement.style.setProperty('--custom-width-mob', this.item.customField28);
      this.customField28=this.item.customField28;
    }
  }else{
    this.categoryId4 = this.border;
    this.categoryId5 = this.displayTitle;
  }

    let countryCode = this.currencyConversionService.getCountryCode();
    if (countryCode == null) {
      this.countryCode = "INR";
    }
    else {
      this.countryCode = countryCode;
    }

    this.getCustomerCount();

  }

  public productsByCategory() {

    if (this.other == 'category') {
      this.categoryId = this.commonService.getEncryptedId(parseInt(this.id))
      this.productService.productsByCategoryIdx(decodeURIComponent(this.categoryId), this.page, this.size, this.sortOrder).subscribe(
        res => {
          if(res!=null){
            this.categoryProducts = res['content'];
            this.count = res['totalElements'];
            this.isEmpty = res['empty'];
            this.pageSize = res['totalPages']
            }else{
              this.categoryProducts = [];
              this.count = 0;
              this.isEmpty = true;
              this.pageSize = 0
            }
        }

      )
    } else {

      let methodName="getProductBySearch";
         let search=''
         if(this.other=='productScope'){
           methodName="getProductByCustomProductId";
           search=this.id
         }else{

            let value = 1;
          search = "isInStock:" + '*' + value + '*'
               + "' AND featured:" + '*' + value + '*' + "' "
         }
       

         this.productService[methodName](search, this.page, this.size, this.sortOrder).subscribe(
        res => {
          this.categoryProducts = res['content'];
          this.count = res['totalElements'];
          this.isEmpty = res['empty'];
          this.pageSize = res['totalPages']
        })
    }
  }


  productsByList(){

    this.categoryProducts = this.products;
  }


  


  ngAfterViewInit() {
    this.config = {
      observer: true,
      slidesPerView: this.mobileShow,
      spaceBetween: 10,
      keyboard: true,
      navigation: true,
      pagination: false,
      grabCursor: true,
      loop: false,
      preloadImages: false,
      lazy: true,
      breakpoints: {
        480: {
          slidesPerView: 3
        },
        740: {
          slidesPerView: 3
        },
        960: {
          slidesPerView: 3
        },
        1280: {
          slidesPerView: 4
        },
        1500: {
          slidesPerView: this.slidesPerView
        }
      }
    }
  }


  goToCategory() {

    this.commonService.goToCategory(1, parseInt(this.id));
  }
  values: any = "*values*";
  getCustomerCount() {

    let authourized = this.userService.getAuthenticatedUser();
  if (authourized) {


    this.cartServices.geWishListProduct();

    this.cartServices.getCustomerCartProductCount().subscribe(
      (response) => {


        this.values = response;

        let value = localStorage.getItem(Constant.CART_COUNT);
        if (value == null) {
          let encryptedValue = btoa(JSON.stringify(response));

          this.userService.storeProductCartCount(encryptedValue);
        }
      },
      (err) => {}
    );
  }
}




}
