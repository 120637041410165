<mat-toolbar class="primary" fxLayoutAlign="space-between center" fxLayout="row" >
    <span style="color: white;">Add Product</span>
    <button style="color: white;" (click)="close()" mat-icon-button>
        <mat-icon>close</mat-icon>
    </button>
</mat-toolbar>
<div class=" products-padding">


    <div class="editSets" fxLayout="column">

        <calsoft-list (filterChangeColumn)="onFilterChange($event)" [progress]="spinner" name="Product"
            [columns]="columns" (filterChange)="onFilterChange($event)">

            <div id="responsieTable" class="container12">
                <div [ngClass]="elements>=5?'minimum':'none'">
                    <table class="rwd-table">
                        <tbody>
                            <tr>
                                <th fxFlex="20">Name</th>
                                <th fxFlex="20">Price</th>
                                <th fxFlex="20">Special Price</th>
                                <th fxFlex="10">Qty</th>
                                <th fxFlex="10">Action</th>

                            </tr>
                            <tr *ngFor="let product of productList;let i=index">
                                <calsoft-product-detail [userId]="userId" [data]="configData"
                                 [productList]="productData" [finalData]="finalData"   [product]="product" (productAdded)="ProductAddedData($event)"></calsoft-product-detail>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            <div *ngIf="productList.length==0" class="padding-10" fxLayout="row" fxLayoutAlign="center center">
                <span>No Records Found</span>
            </div>

            <div fxLayout="row" class="paginator" fxLayoutAlign="end center" fxLayoutGap="5px">
                <mat-spinner [diameter]="20" *ngIf="spinner"></mat-spinner>
                <mat-paginator class="paginator" (page)="getUpdate($event)" showFirstLastButtons [length]="pageList"
                    [pageIndex]="page" [pageSize]="10" [pageSizeOptions]="[5, 10]"> </mat-paginator>
            </div>
        </calsoft-list>
    </div>




    <div fxLayoutAlign="end center" class="products-padding" fxLayoutGap="10px">

        <button (click)="close()" [disabled]="text !='OK'" mat-raised-button>
            {{text}}
            <mat-icon *ngIf="text !='OK' ">
                <mat-spinner color="primary" diameter="20">
                </mat-spinner>
            </mat-icon>
        </button>
    </div>
</div>