<div *ngIf="item.customField25==1">
    <div>
        <div class="top-image"  [style.backgroundImage]="'url(' + serverPath1+item.customField6 + ')'">
            <div class="text-overlay">
                <div class="title">
                    <h2 [innerHTML]="item.title"><b></b></h2>
                </div>
                <div class="descrption">
                    <h5 style="color: #ffffff99;" [innerHTML]="item.customField17"></h5>
                </div>
            </div>       
            <div class="search-box-new">
                <div fxLayout="row">
                    <div fxFlex="60" fxFlex.lt-md="100" class="paddiv" >
                        <calsoft-search3></calsoft-search3>
                    </div>
    
                    <div fxFlex="40" class="category-select">
                        <mat-form-field>
                            <mat-label>Select Category</mat-label>
                            <mat-select>
                                <mat-option *ngFor="let object of img;let i=index " >{{object.customField4}}</mat-option>                          
                            </mat-select>
                          </mat-form-field>
                    </div>
                </div>
            </div>
        
        </div>
    </div>
    
    <div fxLayout="row" class="boxnew" *ngIf="img.length > 0">
        <div class="scroll-container">
            <div fxFlex *ngFor="let item of img" class="div2" fxLayoutAlign="center center" (click)="openInNewWindow(item.customField7)">
                
                <h4 style="margin-bottom: 0px;">{{item.customField4}}</h4>
            </div>
        </div>
    </div>
</div>

<div *ngIf="item.customField25==2">
    <div>
        <div class="top-image"  [style.backgroundImage]="'url(' + serverPath1+item.customField6 + ')'">
            <div class="text-overlay">
                <div class="title">
                    <h2 [innerHTML]="item.title"><b></b></h2>
                </div>
                <div class="descrption">
                    <h5 style="color: #ffffff99;" [innerHTML]="item.customField17"></h5>
                </div>
            </div>       
            <div class="search-box-new">
                <div fxLayout="row">
                    <div fxFlex="60" fxFlex.lt-md="100" class="padNew" >
                        <calsoft-search3></calsoft-search3>
                    </div>
    
                    <!-- <div fxFlex="40" class="category-select">
                        <mat-form-field>
                            <mat-label>Select Category</mat-label>
                            <mat-select>
                                <mat-option *ngFor="let object of img;let i=index " >{{object.customField4}}</mat-option>                          
                            </mat-select>
                          </mat-form-field>
                    </div> -->
                </div>
            </div>
        
        </div>
    </div>
    
    <!-- <div fxLayout="row" class="boxnew" *ngIf="img.length > 0">
        <div class="scroll-container">
            <div fxFlex *ngFor="let item of img" class="div2" fxLayoutAlign="center center" (click)="openInNewWindow(item.customField7)">
                
                <h4 style="margin-bottom: 0px;">{{item.customField4}}</h4>
            </div>
        </div>
    </div> -->
</div>

