import { Component, Input } from '@angular/core';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'calsoft-animated-scroll',
  templateUrl: './animated-scroll.component.html',
  styleUrls: ['./animated-scroll.component.scss']
})
export class AnimatedScrollComponent {
  @Input() data : any;
  @Input() item : any;
  @Input() images : any;
    public bannerServerPath = environment.commonImageApi + '/commonSlider/';
  
    public serverPath1 = environment.commonImageApi + '/home/';
  sections = [
    {
      image: 'image1.jpg',
      alt: 'Influencers',
      title: 'Influencers and Publishers',
      description: `Have you built a large audience of followers who are interested in business tools? Whether
      you are a blogger, YouTuber, website owner, or anyone with a relevant subscriber base, we
      want you as a Zoho affiliate! Recommend our products to your audience and watch the
      commissions accumulate.`
    },
    {
      image: 'assets/img/feature-options.png',
      alt: 'Digital Marketing Agencies',
      title: 'Digital Marketing Agencies',
      description: `Do you run an agency where you help other companies grow? Help your clients scale their
      businesses by implementing Zoho's streamlined digital workflows. Our cost-effective apps
      will help you deliver profitable growth—leading to satisfied clients with larger budgets to spend
      on your services.`
    },
    {
      image: 'assets/img/feature-options.png',
      alt: 'Digital Marketing Agencies',
      title: 'Digital Marketing Agencies',
      description: `Do you run an agency where you help other companies grow? Help your clients scale their
      businesses by implementing Zoho's streamlined digital workflows. Our cost-effective apps
      will help you deliver profitable growth—leading to satisfied clients with larger budgets to spend
      on your services.`
    },
    {
      image: 'assets/img/feature-options.png',
      alt: 'Digital Marketing Agencies',
      title: 'Digital Marketing Agencies',
      description: `Do you run an agency where you help other companies grow? Help your clients scale their
      businesses by implementing Zoho's streamlined digital workflows. Our cost-effective apps
      will help you deliver profitable growth—leading to satisfied clients with larger budgets to spend
      on your services.`
    },
    {
      image: 'assets/img/feature-options.png',
      alt: 'Digital Marketing Agencies',
      title: 'Digital Marketing Agencies',
      description: `Do you run an agency where you help other companies grow? Help your clients scale their
      businesses by implementing Zoho's streamlined digital workflows. Our cost-effective apps
      will help you deliver profitable growth—leading to satisfied clients with larger budgets to spend
      on your services.`
    },
    {
      image: 'assets/img/feature-options.png',
      alt: 'Digital Marketing Agencies',
      title: 'Digital Marketing Agencies',
      description: `Do you run an agency where you help other companies grow? Help your clients scale their
      businesses by implementing Zoho's streamlined digital workflows. Our cost-effective apps
      will help you deliver profitable growth—leading to satisfied clients with larger budgets to spend
      on your services.`
    },
    // Add more sections as needed
  ];
}
