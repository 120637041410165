<div class="coupon-container">
    <div class="header">
      <h2>Apply Coupons</h2>
      <button matDialogClose mat-icon-button class="close-button">
        <mat-icon>close</mat-icon>
      </button>
    </div>
  
    <div class="coupon-list" *ngFor="let item of couponList">
      <div class="card">
        <div class="coupon-header">
          <div
            *ngIf="item.salesRuleCoupon"
            class="couponCode"
            (click)="selectCoupon(item.salesRuleCoupon[0].code)"
          >
            {{ item.salesRuleCoupon[0].code }}
          </div>
        </div>
        <div class="coupon-details">
          <p>
            <span class="label">Validity:</span>
            <b>{{ item.fromDate | date }}</b> - <b>{{ item.toDate | date }}</b>
          </p>
          <p>
            <span class="label">Description:</span>
            <span>{{ item.description }}</span>
          </p>
        </div>
      </div>
    </div>
  </div>
  