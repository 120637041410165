import { Component, Inject, Input } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { Constant } from 'src/app/constants/constant';
import { ConfigServiceService } from 'src/app/services/config-service.service';
import { ContactService } from 'src/app/services/contact.service';
import { downloads } from '../../util/calsoft-validators';
import { ProductService } from 'src/app/services/product.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-form-popup',
  templateUrl: './form-popup.component.html',
  styleUrls: ['./form-popup.component.scss']
})
export class FormPopupComponent {

  data: any;

  text: string;

  regConfig: any[] = [];

  @Input() input: any;

  @Input() item: any;

  popupForm: any;
  popupText: any;
  action: any;


  constructor(private dialogRef: MatDialogRef<FormPopupComponent>,
    public snackBar: MatSnackBar,
    private contactService: ContactService,
    private configService: ConfigServiceService,
    private router: Router,
    @Inject(MAT_DIALOG_DATA) data,
    private dialog: MatDialog,
    private productService: ProductService,
  ) {

    this.data = data;

  }

  init: boolean = false;

  formId:any;

  formTitle: any;

  fileName: any=undefined;

  serverPath = environment.serverAPI2+"/api/core/download/pdf?filename=/gallery/";


  ngOnInit() {


    this.formId = this.data.formId;

    this.formTitle = this.data.title;

    this.fileName = this.data.fileName;

    
    this.action = this.data.action;


    if(this.formId!=null){
      this.createDynamicForm(this.formId);
    }

    if (this.data.action === 'download') {
     
    } else if (this.data.action === 'form') {
      
    }

    this.text = 'Submit';

  }



  createDynamicForm(formGroupId) {
    this.contactService.getDynmaicForm(formGroupId).subscribe((data) => {
      let value: any = data
      this.regConfig = value;
      this.init = true;
    });
  }


  close() {
    this.dialogRef.close({ event: 'close' })
  }


  submit(value: any) {

    let filteredList = this.regConfig
      .filter(item => item.inputType.toLowerCase().includes('button'))
      .map(item => ({ label: item.label }));

    filteredList.forEach(label => {
      if (value.hasOwnProperty(label.label)) {
        delete value[label.label];
      }
    });

    let filteredListFile = this.regConfig
      .filter(item => item.inputType.toLowerCase().includes('file'))
      .map(item => ({ label: item.name }));

    filteredListFile.forEach(label => {
      if (value.hasOwnProperty(label.label)) {
        const newKey = label.label + '_file';
        value[newKey] = value[label.label];
        delete value[label.label];
      }
    });

    let object = {};
    let keysToCheck = ["phoneNumber", "email", "name"];
    let keysList = keysToCheck.filter(key => value.hasOwnProperty(key));
    keysList.forEach(key => {
      object[key] = value[key];
    });

    object['formGroupId'] = this.data['formId'];
    object['value'] = JSON.stringify(value);
    this.saveForm(object);

  }


  downloadPdf() {
    if(this.fileName!=undefined){
      let smallImage = "/gallery/" + this.fileName;
      this.productService.downloadPdf(smallImage).subscribe(
        data => {
          downloads(data,this.fileName);
        },
      )
    }
  }

  saveForm(object) {
    if (this.text == 'Submit') {
      this.contactService.saveForm(object).subscribe(
        (response) => {
          if (response['status_code'] == Constant.RESPONSE_SUCCESS) {
            this.text = "Submit";
            this.snackBar.open(response['status_message'], '×', { panelClass: 'success', verticalPosition: 'bottom', duration: 3000 });
           if(this.action=="download"){
            if(this.fileName!=null){
              this.downloadPdf();
            }
           }
  
            this.close();
            if(this.action!="download"&&this.action!="form" ){
          if(this.fileName==null){
            this.router.navigate([''])
          }
        }
   
          }
          else {
            this.text = "Submit";
            this.snackBar.open(response['status_message'], '×', { panelClass: 'error', verticalPosition: 'bottom', duration: 3000 });
          }
        },
        (err) => { this.text = "Submit" });
    }
    else {

    }
  }



}
