import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { CouponRoutingModule } from './coupon-routing.module';
import { CouponComponent } from './coupon.component';
import { CustomFlexLayoutModule } from 'src/app/flex-layout/custom-flex-layout.module';


@NgModule({
  declarations: [CouponComponent],
  imports: [
    CommonModule,
    CouponRoutingModule,
    CustomFlexLayoutModule
  ]
})
export class CouponModule { }
