import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { CommonService } from 'src/app/services/common.service';
import { SubscriptionPriceService } from 'src/app/services/subscription-price.service';

@Component({
  selector: 'calsoft-plan-compare-type12',
  templateUrl: './plan-compare-type12.component.html',
  styleUrls: ['./plan-compare-type12.component.scss']
})
export class PlanCompareType12Component {

  isChecked: boolean = false;
  pricedetailnew: any;
  selectedSubscription: { price: number; title: string } | null = null;

  constructor(
    private cdr: ChangeDetectorRef,
    private commonService: CommonService,
    private subscriptionPriceService: SubscriptionPriceService,
  ) {}


  @Input() item: any;
  @Input() data: any;

  @Input() type: any = 1;


  ngOnInit() {
    
    this.pricedetailnew = JSON.parse(this.item.customField30);

    let index = this.findCenterElement(this.pricedetailnew);

    //console.log("index" , index);
    

    this.selectSubscription(index.p_price_annualy, index.title2, this.item.customField24, this.item.customField27)

  }


  toggleChanged() {


    if (this.selectedSubscription) {
      const computedPrice = this.getComputedPrice(this.selectedSubscription.price);
      this.selectedPrice = computedPrice;

      const taxPercentage = this.item.customField21;
      const isExclusive = this.item.customField22 === 'Exclusive';

      let totalCost = computedPrice;
      let taxAmount = 0.0;

      if (isExclusive) {
        taxAmount = (computedPrice * taxPercentage) / 100;
        totalCost = parseFloat(computedPrice.toString()) + taxAmount;
      }


      this.subscriptionPriceService.updateSubscriptionDetails(
        computedPrice,
        taxPercentage,
        totalCost,
        taxAmount,
        this.selectedSubscription?.title
      );

      this.updateUI();

      this.cdr.detectChanges();

    }

  }
  updateUI() {
    this.selectedPrice = this.getComputedPrice(this.selectedSubscription?.price || 0);
  }

  getComputedPrice(basePrice: number): number { 
   return this.isChecked ? basePrice * this.item.customField21 : basePrice;
  }

  findCenterElement(arr: any[]): any {
    if (arr.length === 0) return undefined;
    const middleIndex = Math.floor(arr.length / 2);
    return arr[middleIndex];
  }

  openInNewWindow(url: string) {
    if (url) {
      const fullURL = this.prependProtocol(url);
      this.commonService.goToLink(url)
    }
  }

  prependProtocol(url: string): string {
    if (!url.includes('http://') && !url.includes('https://')) {
      return 'https://' + url;
    }
    return url;
  }

  selectedPrice: any = null;
  isSelected(price: number): boolean {
    return this.selectedPrice === this.getComputedPrice(price);
  }

  finalPrice:any;

  selectSubscription(price: any, title: string, setupCost: any, signupCost: any) {
    
    this.selectedSubscription = { price, title };
    const computedPrice = this.getComputedPrice(price);

    this.selectedPrice = price;

    this.finalPrice = parseFloat(price) + parseFloat(setupCost) + parseFloat(signupCost);

    let taxPercentage = this.item.customField21;
    const isExclusive = this.item.customField22 === 'Exclusive';

    let totalCost = price;
    let taxAmount = 0.0;
    if (isExclusive) {
      taxAmount = (this.finalPrice * taxPercentage) / 100;
      totalCost = parseFloat(this.finalPrice) + taxAmount;
    } else {

    }

    if(title!=null && title!=undefined ){
      this.subscriptionPriceService.updateSubscriptionDetails(computedPrice, taxPercentage, totalCost, taxAmount, title);
    }
   
    this.subscriptionPriceService.updateSubscriptionDetailsTwo(setupCost, signupCost);

    this.updateUI();

  }

}
