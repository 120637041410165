import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CustomFlexLayoutModule } from 'src/app/flex-layout/custom-flex-layout.module';

import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { LoaderComponent } from './loader/loader.component';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import { MatDialogModule } from '@angular/material/dialog';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatButtonModule } from '@angular/material/button';
import { CommonSignInComponent } from './CommonSignIn/CommonSignIn.component';
import { DirectiveModule } from './utils/directive/directive.module';
import { PipesModule } from '../theme/pipes/pipes.module';
import { AngularFireModule } from '@angular/fire/compat';
import { AngularFireAuthModule } from '@angular/fire/compat/auth';
import { environment } from 'src/environments/environment';
import { LocationComponent } from './location/location.component';
import { MapLocationComponent } from './map-location/map-location.component';
import { MatMenuModule } from '@angular/material/menu';
import { SwiperModule } from '../theme/swiper/swiper.module';
import { LocationMapAllComponent } from './location-map-all/location-map-all.component';
import { DynamicComponentModule } from './component/dynamic-component.module';
import { ReviewRatingModule } from '../pages/review-rating/review-rating.module';
import { Review } from '../models/review';
import { ApplyCouponComponent } from './apply-coupon/apply-coupon.component';




@NgModule({
  declarations: [
    LoaderComponent,
    CommonSignInComponent,
    LocationComponent,
    MapLocationComponent,
    LocationMapAllComponent,
    ApplyCouponComponent
  

  ],
  imports: [
    CommonModule,
    RouterModule,
    CustomFlexLayoutModule,
    SwiperModule,
    FormsModule,
    ReactiveFormsModule,
    MatSidenavModule,
    MatToolbarModule,
    MatIconModule,
    MatInputModule,
    MatProgressSpinnerModule,
    MatListModule,
    MatDialogModule,
    MatSnackBarModule,
    MatButtonModule,
    DirectiveModule,
    PipesModule,
    AngularFireModule.initializeApp(environment.firebase),
    AngularFireAuthModule,
    MatListModule,
    MatMenuModule,
    DynamicComponentModule,
    ReviewRatingModule


  ], exports: [
    RouterModule,
    CustomFlexLayoutModule,
    SwiperModule,
    MatSidenavModule,
    MatToolbarModule,
    MatIconModule,
    MatInputModule,
    LoaderComponent,
    MatProgressSpinnerModule,
    MatListModule,
    MatDialogModule,
    MatSnackBarModule,
    MatButtonModule,
    MatInputModule,
    FormsModule,
    ReactiveFormsModule,
    CommonSignInComponent,
    LocationComponent,
    MapLocationComponent,
    LocationMapAllComponent,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]

})
export class SharedModule { }
