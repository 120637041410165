import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { AnimatedScrollRoutingModule } from './animated-scroll-routing.module';
import { AnimatedScrollComponent } from './animated-scroll.component';
import { LayoutModule } from '@angular/cdk/layout';
import { CustomFlexLayoutModule } from 'src/app/flex-layout/custom-flex-layout.module';
import { EditorTextModule } from '../../home/editor-text/editor-text.module';


@NgModule({
  declarations: [
    AnimatedScrollComponent,
  
        
  ],
  imports: [
    CommonModule,
    EditorTextModule,
    AnimatedScrollRoutingModule,
    CustomFlexLayoutModule
  ],
  exports:[AnimatedScrollComponent]
})
export class AnimatedScrollModule { }
