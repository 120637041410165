<div mat-dialog-title class="title-head" class="primary" fxLayout="row" fxLayoutAlign="space-between center" color="primary">
    <span style="color: white; padding: 10px;">
      {{formTitle || 'Form'}}
    </span>
    <button (click)="close()" mat-icon-button>
        <mat-icon style="color: white;">
            close
        </mat-icon>
    </button>
  </div>
  <mat-dialog-content style=" MARGIN: 0PX; 
  padding: 0px;" ngStyle.lt-md="max-height: 100%;">
  
    <div fxLayout="column" class="padding-10">
  
        <div fxFlex="100" class="form">
            <div fxFlex="100" *ngIf="init">
                <dynamic-form [layout]="1" fxFlex="100" [fields]="regConfig" (submit)="submit($event)">
                </dynamic-form>
            </div>
        </div>
  
    </div>
  
 </mat-dialog-content>