import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { PyramidtextRoutingModule } from './pyramidtext-routing.module';
import { PyramidtextComponent } from './pyramidtext.component';
import { CustomFlexLayoutModule } from 'src/app/flex-layout/custom-flex-layout.module';


@NgModule({
  declarations: [
    PyramidtextComponent
  ],
  imports: [
    CommonModule,
    PyramidtextRoutingModule,
    CustomFlexLayoutModule
  ],
  exports:[PyramidtextComponent]
})
export class PyramidtextModule { }
