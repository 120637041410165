
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnChanges, OnInit, SimpleChanges, TemplateRef, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';

import { Constant } from 'src/app/constants/constant';
import { CartService } from 'src/app/services/cart.service';
import { CategoryService } from 'src/app/services/category.service';
import { CommonService } from 'src/app/services/common.service';
import { ConfigServiceService } from 'src/app/services/config-service.service';
import { ProductService } from 'src/app/services/product.service';
import { UserService } from 'src/app/services/user.service';
import { environment } from 'src/environments/environment';
import { FormPopupComponent } from './form-popup/form-popup.component';
import { TextwithformPopupComponent } from './textwithform-popup/textwithform-popup.component';
import { AboutService } from 'src/app/services/about.service';
import { SwiperConfigInterface } from 'src/app/theme/swiper/swiper.module';
@Component({
  selector: 'calsoft-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss'],
  
})
export class LayoutComponent  implements OnInit ,OnChanges{
  @Input() value: string;

  @Input() homePageData: any;

  @Input() type: any=1;
  recentlyViewedProducts: Object;
  mobileLayout:any=false;

  @Input() pageNated: any = false;
  
  public serverPath = environment.commonImageApi + '/home/';

  public serverPath1 = environment.commonImageApi + '/commonSlider/';

  LogoSlider = {
    nav: false,
    dots: false,
    items: 1,
    margin: 20,
    loop: false,
    autoHeight: true,
    autoplay: true,
    
    responsive: {
        767: {
            items: 1
        },
        576: {
            items: 1
        },
        480: {
            items: 1
        },
        0: {
            items: 1
        }
    }
}

LogoSlider1 = {
  nav: false,
  dots: false,
  items: 1,
  margin: 20,
  loop: true,
  autoHeight: true,
  autoplay: true,
  
  responsive: {
      767: {
          items: 4
      },
      576: {
          items: 4
      },
      480: {
          items: 2
      },
      0: {
          items: 2
      }
  }
}

  
   brands1=[  
    { name: 'aloha', image: 'assets/images/brands/aloha.png' },
    { name: 'dream', image: 'assets/images/brands/dream.png' },  
    { name: 'congrats', image: 'assets/images/brands/congrats.png' },
    { name: 'best', image: 'assets/images/brands/best.png' },
    { name: 'original', image: 'assets/images/brands/original.png' },
    { name: 'retro', image: 'assets/images/brands/retro.png' },
    { name: 'king', image: 'assets/images/brands/king.png' },
    { name: 'love', image: 'assets/images/brands/love.png' },
    { name: 'the', image: 'assets/images/brands/the.png' },
    { name: 'easter', image: 'assets/images/brands/easter.png' },
    { name: 'with', image: 'assets/images/brands/with.png' },
    { name: 'special', image: 'assets/images/brands/special.png' },
    { name: 'bravo', image: 'assets/images/brands/bravo.png' }
];
  featuredProducts: any;
  onSaleProducts: any;
  topRatedProducts: any;
  newArrivalsProducts: any;
  customField17:any;
  customField18:any;

  mediaGallery: any;
  sortParam: any = 'id' + ',' + Constant.Dsc;


  public youtubeUrl = environment.youtubeUrl;
  homePageProduct: any = [];

  public page: any = 0;
  public size: any = 3;
  spinner: boolean = false;
  pageSize: any;
  slides: any = [];
  empty: any;
  layout: any;
  totalNumberOfElements: any;
  showCartIcon: any;
  showCartIncremented: any;
  showConfigurableDropdown: any;
  productImageWidth: any;
  productImageType: any;
  productImageWidthMob: any;
  productImageHeightMob: any;
  categoryArray: any=[];
  bannerHeight: any;
  bannerWidth: any;
  bannerMobWidth: any;
  bannerMobHeight: any;
  bannerTabletHeight:any;
  bannerTabletWeight:any;
  dataLoaed:any=false;
  fontFamily: string ; 
  height:any;
  width:any;
  gapBetweenImage:any;
  @Input() item: any;

  @Input() data: any;
  customField12:any;
  customField11:any;
  customField13:any=0;
  customField14:any;
  customField19:any;
  customField20:any=0;
  heightMob:any;
  widthMob:any;
  displayMobileImage:any;
  mobileGapBetweenImage:any;
  altImage:any;
  autoplay:any=0;
  homePageLayoutLoadedWeb: any;
  homePageLayoutLoadedMob: any;
  total: number;


  constructor(

    private commonService: CommonService,
    private productService: ProductService,
    private aboutService: AboutService,
    private router: Router,
    private configService :ConfigServiceService,
    private cartServices:CartService,
    private userService:UserService,
    public sanitizer: DomSanitizer,
    public dialog: MatDialog,
    
    
  ) {
    this.checkScreenSize();

  }
  ngOnChanges(changes: SimpleChanges): void {
   
    
  }

  public brands = [];
  public banners = [];


  GapValue:any=70

  GapValue2:any=30
 
  ngOnInit() {
         
    this.getConfigData();

  }
  isMobileView: boolean = false;


  checkScreenSize() {
      this.isMobileView = window.innerWidth <= 768;  // Adjust this value based on your mobile breakpoint
      window.onresize = () => {
          this.isMobileView = window.innerWidth <= 768;
      };
  }

  TextwithFormPopup(data:any) {
    if (window.innerWidth > 768) {

       let dialogRef = this.dialog.open(TextwithformPopupComponent, {
          width: '850px',
          data: { item: data}
       });

       dialogRef.disableClose = true;
       dialogRef.afterClosed().subscribe(result => {

       });

    }
    else {
       let dialogRef = this.dialog.open(TextwithformPopupComponent, {
          minWidth: '100vw', height: '100vh',
          data: { item: data}
       });

       dialogRef.disableClose = true;
       dialogRef.afterClosed().subscribe(result => {

       });
    }

 }


  getSafeUrl(url: string) {
    return this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }

  getHomePage() {
        
    this.page = 0;
    if (window.innerWidth > 768) {

      this.mobileLayout=false;
      this.size = this.homePageLayoutLoadedWeb==undefined?3: this.homePageLayoutLoadedWeb;
    } else {
      this.mobileLayout=true;
      this.size = this.homePageLayoutLoadedMob==undefined?5:this.homePageLayoutLoadedMob;
    }
    this.productService.getHomePageProduct(this.page, this.size,this.value).subscribe(
      res => {

        this.empty = res['data']['empty']
        this.pageSize = res['data']['totalPages']

        this.totalNumberOfElements = res['data']['numberOfElements'] - 1;
        this.homePageProduct = res['data']['content'];

        this.total = res['data']['totalElements'] - 1;

       this.dataLoaed=true;
      

      }
    )
  }


  stripHtmlTags(value: string | undefined): string {
    return value ? value.replace(/<\/?[^>]+(>|$)/g, "") : "";
}
private hasMoreData = true;

  onScroll() { 

    if (window.innerWidth > 768) {

      this.mobileLayout=false;
      this.size = this.homePageLayoutLoadedWeb==undefined?3: this.homePageLayoutLoadedWeb;
    } else {
      this.mobileLayout=true;
      this.size = this.homePageLayoutLoadedMob==undefined?5:this.homePageLayoutLoadedMob;
    }
  
    
    if (this.type === 1) {
      if (this.page < this.pageSize) {
        this.spinner = true;
        this.page++;
        this.productService.getHomePageProduct(this.page, this.size, this.value).subscribe(
          res => {
            // Append products to the array
            const products = res['data']['content']
            this.homePageProduct.push(...products);
          this.homePageProduct.sort((a, b) => a.position - b.position);
            this.spinner = false;
          
  
            // Check if no products are returned
            if (this.homePageProduct.length < 1) {
              console.warn("No products found.");
            }
          },
          error => {
            console.error("Error loading products:", error);
            this.spinner = false; // Reset spinner on error
          }
        );
      } else {
        this.spinner = false;
      }
    }  else if (this.pageNated && this.type === 2) {
      if (this.page < this.pageSize) {
        this.spinner = true;
        this.page++;
        let value = this.homePageProduct[0].uuid;
        this.aboutService.getPageByUUid(value, this.page, this.size).subscribe(
          (res: any) => {
            const products = res || [];
            if (products.length === 0) {
              this.hasMoreData = false; // Stop further calls
            } else {
              this.homePageProduct.push(...products);
             this.homePageProduct.sort((a, b) => a.position - b.position);
            
              this.page++; // Increment page for next call
            }
            this.spinner = false;
          },
          error => {
            console.error("Error loading products:", error);
            this.spinner = false;
          }
        );
      } else {
        this.spinner = false;
      }
    }
  
  }
  
  goToCategory(menu) {

    this.commonService.goToCategory(menu.title, menu.value);

  }
  goToCategories(id: any) {
    if(id>0){
      this.commonService.goToCategory(1, parseInt(id));
    }

  }

  dynamicFormPopup(formGroupId:any) {
    if (window.innerWidth > 768) {

       let dialogRef = this.dialog.open(FormPopupComponent, {
          width: '790px',
          data: { formId: formGroupId}
       });

       dialogRef.disableClose = true;
       dialogRef.afterClosed().subscribe(result => {

       });

    }
    else {
       let dialogRef = this.dialog.open(FormPopupComponent, {
          minWidth: '100vw', height: '100vh',
          data: { formId: formGroupId}
       });

       dialogRef.disableClose = true;
       dialogRef.afterClosed().subscribe(result => {

       });
    }

 }

  imageNavigation(id){


    if(id>0){
      this.commonService.goToCategory(1, parseInt(id));
    }

  }

  gotoCat(i, item) {


    if (i == 0 && item.customField4 > 0) {

      this.commonService.goToCategory(1, parseInt(item.customField4));
    } else if (i == 1 && item.customField6 > 0) {
      this.commonService.goToCategory(1, parseInt(item.customField6));
    } else if (i == 2 && item.customField7 > 0) {
      this.commonService.goToCategory(1, parseInt(item.customField7));
    } else if (i == 3 && item.customField8 > 0) {
      this.commonService.goToCategory(1, parseInt(item.customField8));
    }
  }

  productImageHeight:any;
  getConfigData() {
    this.configService.getConfigData()
      .subscribe(
        data => {
          
          this.commonService.setCurrencyValue(data['currencyBase'])
          this.showCartIcon= data['showCartProductPage'];

          this.showCartIncremented=data['showCartIncremented'];

          this.showConfigurableDropdown=data['showConfigurableDropdownProductPage'];
          this.productImageHeight=data['productsImageHeight']
          this.productImageWidth=data['productsImageWidth']
          this.data=data;
          this.productImageType=data['productsImageType']
          this.productImageWidthMob=data['productsImageWidthMob']
          this.bannerHeight=data['bannerHeight'];
          this.bannerWidth=data['bannerWidth'];
          this.bannerMobWidth=data['bannerMobWidth'];
          this.bannerMobHeight=data['bannerMobHeight'];
          this.homePageLayoutLoadedMob=data['homePageLayoutLoadedMob'];
          this.homePageLayoutLoadedWeb=data['homePageLayoutLoadedWeb'];
          this.productImageHeightMob=data['productsImageHeightMob'];
          
          if(this.type==1){
            this.getHomePage();
         
          }else{
            this.homePageProduct =this.homePageData;

            if(this.pageNated){
              this.pageSize = this.homePageData[0]['pageSize']

              this.totalNumberOfElements = this.homePageData[0]['totalNumberOfElements'] - 1;
  
              this.total = this.homePageData[0]['totalElements'] - 1;
            }


          }
         
          if(this.height !== undefined){
            this.height=this.item.height;
          }
      
          if(this.width !== undefined){
            this.width=this.item.width;
          }
      
          if(this.widthMob !== undefined){
            this.widthMob=this.item.widthMob;
          }
      
          if(this.heightMob !== undefined){
            this.heightMob=this.item.heightMob;
          }
      
          if(this.gapBetweenImage !== undefined){
            this.gapBetweenImage=this.item.gapBetweenImage;
          }
      
          if(this.mobileGapBetweenImage !== undefined){
            this.mobileGapBetweenImage=this.item.mobileGapBetweenImage;
          }
          
          if(this.displayMobileImage !== undefined){
            this.displayMobileImage=this.item.displayMobileImage;
          }
          
          let authourized = this.userService.getAuthenticatedUser();
          if (authourized) {
          this.cartServices.getCustomerCartProductCount()
          this.cartServices.geWishListProduct();
          }
          
          }, error => {

          }
          );
}


link(url) {

  this.commonService.goToLink(url)
 
}

scroll(id) {

  this.commonService.scrollDown(id)
 
}

imagesToShow = 2; // Initial number of images to show
loadMoreStep = 2; // Number of images to load each time "Load More" is clicked

  onLoadMore() {
    this.imagesToShow += this.loadMoreStep;
  }


calculateFlexValue(item): string {
  if (item.layout === "2") {
    return '50';
  } else if (item.layout === "3") {
    return '33';
  } else if (item.layout === "4") {
    
    
    return `calc(25% - ${item.gapBetweenImage})`;
  } else {
    return '10';
  }
}

prependProtocol(url: string): string {
  if (!url.includes('http://') && !url.includes('https://')) {
    return 'https://' + url;
  }
  return url;
}

openInNewWindow(url: string) {
  if (url) {
    const fullURL = this.prependProtocol(url);
    this.commonService.goToLink(url)
  }
}
trackByFn(index: number, item: any): any {
  return item.position; // or return item.id; if you have a unique identifier
}

trackImgByFn(index: number, item: any): any {
  return item.images.valueId; // or return item.id; if you have a unique identifier
}


@ViewChild('image') image: TemplateRef<any>;
imageSrc:any;

popupDialog(path) {

  this.imageSrc=path;
  let dialogRef = this.dialog.open(this.image, {
    width: '350px',
    disableClose: true,
  });
  dialogRef.disableClose = true;

}




}
