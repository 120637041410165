import { ChangeDetectorRef, Component, DoCheck, Input } from '@angular/core';
import { CommonService } from 'src/app/services/common.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'calsoft-faq',
  templateUrl: './faq.component.html',
  styleUrls: ['./faq.component.scss']
})
export class FaqComponent {
  sections = [
    {
      image: 'image1.jpg',
      alt: 'Influencers',
      title: 'Influencers and Publishers',
      description: `Have you built a large audience of followers who are interested in business tools? Whether
      you are a blogger, YouTuber, website owner, or anyone with a relevant subscriber base, we
      want you as a Zoho affiliate! Recommend our products to your audience and watch the
      commissions accumulate.`
    },
    {
      image: 'assets/img/feature-options.png',
      alt: 'Digital Marketing Agencies',
      title: 'Digital Marketing Agencies',
      description: `Do you run an agency where you help other companies grow? Help your clients scale their
      businesses by implementing Zoho's streamlined digital workflows. Our cost-effective apps
      will help you deliver profitable growth—leading to satisfied clients with larger budgets to spend
      on your services.`
    },
    {
      image: 'assets/img/feature-options.png',
      alt: 'Digital Marketing Agencies',
      title: 'Digital Marketing Agencies',
      description: `Do you run an agency where you help other companies grow? Help your clients scale their
      businesses by implementing Zoho's streamlined digital workflows. Our cost-effective apps
      will help you deliver profitable growth—leading to satisfied clients with larger budgets to spend
      on your services.`
    },
    {
      image: 'assets/img/feature-options.png',
      alt: 'Digital Marketing Agencies',
      title: 'Digital Marketing Agencies',
      description: `Do you run an agency where you help other companies grow? Help your clients scale their
      businesses by implementing Zoho's streamlined digital workflows. Our cost-effective apps
      will help you deliver profitable growth—leading to satisfied clients with larger budgets to spend
      on your services.`
    },
    {
      image: 'assets/img/feature-options.png',
      alt: 'Digital Marketing Agencies',
      title: 'Digital Marketing Agencies',
      description: `Do you run an agency where you help other companies grow? Help your clients scale their
      businesses by implementing Zoho's streamlined digital workflows. Our cost-effective apps
      will help you deliver profitable growth—leading to satisfied clients with larger budgets to spend
      on your services.`
    },
    {
      image: 'assets/img/feature-options.png',
      alt: 'Digital Marketing Agencies',
      title: 'Digital Marketing Agencies',
      description: `Do you run an agency where you help other companies grow? Help your clients scale their
      businesses by implementing Zoho's streamlined digital workflows. Our cost-effective apps
      will help you deliver profitable growth—leading to satisfied clients with larger budgets to spend
      on your services.`
    },
    // Add more sections as needed
  ];
  perks = [
    {
      title: 'End-to-end Sales support',
      image: 'https://www.zohowebstatic.com/sites/zweb/images/affiliate/home/zah-perks-support.png',
      description: 'Create compelling content around Zoho products and drive referrals through your affiliate links. Our dedicated sales team will work their magic to convert your leads into successful deals.'
    },
    {
      title: 'Unlimited Earning Potential',
      image: 'https://www.zohowebstatic.com/sites/zweb/images/affiliate/home/zah-perks-earning-potential.png',
      description: 'The program has no minimum sales requirement and no limits on the referral commissions affiliates can earn. Our top performing affiliates earn thousands of dollars every month—and you can too.'
    },
    {
      title: 'Powerful Suite of Apps',
      image: 'https://www.zohowebstatic.com/sites/zweb/images/affiliate/home/zah-perks-suite-of-apps.png',
      description: 'Over 150,000 businesses across the globe run on Zoho. Our powerful cloud software suite offers deep integrations and a strong presence in over 150 countries.'
    },
    {
      title: 'Dedicated Affiliate Portal & Manager',
      image: 'https://www.zohowebstatic.com/sites/zweb/images/affiliate/home/zah-perks-portal.png',
      description: 'You\'ll get access to a portal with a complete repository of promotional collateral such as toolkits, brand logos, a helpful affiliate primer, and much more.'
    },
    {
      title: 'Growth Opportunity',
      image: 'https://www.zohowebstatic.com/sites/zweb/images/affiliate/home/zah-perks-growth.png',
      description: 'Our affiliate program is designed to reward consistent efforts as you drive revenue to Zoho. As you reach new levels of success, you can upgrade your affiliate membership.'
    }
  ];

  clickedPerk: any = null;
  selectedPerkIndex: number = 0;



  showPerkData(index: number,perk): void {
    this.selectedPerkIndex = index;
    this.clickedPerk = { ...perk }; // Create a new reference
    // console.log('The Clicked Perk is', JSON.stringify(this.clickedPerk));
    this.cdr.detectChanges(); // Trigger change detection

  }
  @Input() item: any;

  @Input() faq: any;


  @Input() data: any;

  panelOpenState: boolean = false;
  item_visible: boolean = true;

  customField15: any;
  customField16: any;
  customField17: any;
  customField18: any;

  public serverPath = environment.commonImageApi + '/commonSlider/';
  public serverPath2 = environment.commonImageApi + '/home/';

  index: number = 0;

  constructor(
    private cdr: ChangeDetectorRef,
    private commonService: CommonService,
  ) {

  }

  ngOnInit() {
    this.index = 0;
    if (this.item?.images?.length) {
      this.selectedPerkIndex = 0;
      this.clickedPerk = { ...this.item.images[0] }; // Create a new object reference
    }
    this.cdr.detectChanges();
  }

  openedPanelTitle: string;

  expandedIndex: any;
  onPanelOpen(title: string, index: number) {
    if (this.expandedIndex !== -1 && this.expandedIndex !== index) {
      this.expandedIndex = -1;
      this.cdr.detectChanges();
    }
    this.expandedIndex = index;
    this.openedPanelTitle = title;
    this.panelOpenState = true;
    this.cdr.detectChanges();
  }

  onPanelClose(index: number) {
    if (this.expandedIndex === index) {
      this.expandedIndex = -1;
      this.openedPanelTitle = null;
      this.panelOpenState = false;
      this.cdr.detectChanges();
    }
  }

  toggleSection() {
    this.item_visible = !this.item_visible;
  }

}
