import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class SubscriptionPriceService {

  private subscriptionPriceSubject = new BehaviorSubject<any>(localStorage.getItem('subscriptionPrice'));
  private subscriptionTaxPercentageSubject = new BehaviorSubject<any>(localStorage.getItem('subscriptionTaxPercentage'));
  private subscriptionTotalCostSubject = new BehaviorSubject<any>(localStorage.getItem('subscriptionTotalCost'));
  private subscriptionTotalTaxAmount = new BehaviorSubject<any>(localStorage.getItem('subscriptionTotalTaxAmount'));
  private subscriptionType = new BehaviorSubject<any>(localStorage.getItem('subscriptionType'));
  private subscriptionSetupCostSubject = new BehaviorSubject<any>(localStorage.getItem('subscriptionSetupCost'));
  private subscriptionSignupCostSubject = new BehaviorSubject<any>(localStorage.getItem('subscriptionSignupCost'));
  private subscriptionImgName = new BehaviorSubject<any>(localStorage.getItem('subscriptionImgName'));

  private amount = new BehaviorSubject<any>(localStorage.getItem('amount'));
  
  private totalGrandTotal = new BehaviorSubject<any>(localStorage.getItem('totalGrandTotal'));

  private layoutConfigMap = new BehaviorSubject<Map<number, number>>(new Map<number, number>());

  
  

  subscriptionPrice$ = this.subscriptionPriceSubject.asObservable();
  subscriptionTaxPercentage$ = this.subscriptionTaxPercentageSubject.asObservable();
  subscriptionTotalCost$ = this.subscriptionTotalCostSubject.asObservable();
  subscriptionTotalTaxAmount$ = this.subscriptionTotalTaxAmount.asObservable();
  subscriptionSetupCost$ = this.subscriptionSetupCostSubject.asObservable();
  subscriptionSignUpCost$ = this.subscriptionSignupCostSubject.asObservable();



  GrandTotalAmount$ = this.totalGrandTotal.asObservable();
  subscriptionType$ = this.subscriptionType.asObservable();

  subscriptionImgName$ = this.subscriptionImgName.asObservable();

  amount$ = this.amount.asObservable();

  updateSubscriptionDetails(price: any, taxPercentage: any, totalCost: any,taxAmount:any , title: any) {
    localStorage.setItem('subscriptionPrice', price);
    localStorage.setItem('subscriptionTaxPercentage', taxPercentage.toString());
    localStorage.setItem('subscriptionTotalCost', totalCost.toString());
    localStorage.setItem('subscriptionTotalTaxAmount', taxAmount.toString());
    localStorage.setItem('subscriptionType', title.toString());
    this.subscriptionPriceSubject.next(price);
    this.subscriptionTaxPercentageSubject.next(taxPercentage);
    this.subscriptionTotalCostSubject.next(totalCost);
    this.subscriptionTotalTaxAmount.next(taxAmount);
    this.subscriptionType.next(title);
  }
  updateSubscriptionDetailsTwo(setupCost: number, signupCost: number) {
    
    localStorage.setItem('subscriptionSetupCost', setupCost.toString());
    localStorage.setItem('subscriptionSignupCost', signupCost.toString());
  
  
    this.subscriptionSetupCostSubject.next(setupCost);
    this.subscriptionSignupCostSubject.next(signupCost);
  }


  updateGrandTotal(totalGrandTotal: any) {
   
    this.layoutConfigMap.next(totalGrandTotal);
  }

  updatesubscriptionImgName(subscriptionImgName: any) {
   
    this.subscriptionImgName.next(subscriptionImgName);
  }

  updateAmount(amount: any) {

    localStorage.setItem('amount', amount);
    this.amount.next(amount);
  }


  private keyValueMap: Map<string, any> = new Map();
  
  private keyValueSubject: BehaviorSubject<Map<string, any>> = new BehaviorSubject(this.keyValueMap);


 isEmpty(): Observable<boolean> {
  return this.keyValueSubject.asObservable().pipe(
    map(map => map.size === 0)
  );
}


getValue(key: string): Observable<any> {
  return this.keyValueSubject.asObservable().pipe(
    map(map => map.get(key))
  );
}


updateValue(key: string, value: any): void {
  this.keyValueMap.set(key, value);
  this.keyValueSubject.next(this.keyValueMap);
}


removeValue(key: string): void {
  this.keyValueMap.delete(key);
  this.keyValueSubject.next(this.keyValueMap);
}


getMap(): Observable<Map<string, any>> {
  return this.keyValueSubject.asObservable();
}


calculateTax(price: any, taxPercent: number, inclusiveTax: number ) {


  let totalCost = price;
  let taxAmount=0.0; 
  if (inclusiveTax==0) {
    taxAmount = (price * taxPercent) / 100;
    
  } else {
    if(taxPercent!=0){
      // taxAmount = (price * 100) / (100 + taxPercent);

      taxAmount = (taxPercent)* 2;
    }
 
  }

  taxAmount = Math.round(taxAmount * 100) / 100;

 return taxAmount;
}




private formSubject = new BehaviorSubject<any | null>(null);

form$ = this.formSubject.asObservable(); 


updateFormValue(form: any) {
  this.formSubject.next(form);
}



  
}
